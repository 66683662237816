import { EqualityCheck, SimpleDate } from "@idot-digital/calendar-api";
import { getDaysBetween, getMinuteOfDay } from "../../../Functions/functions";
import { deepCopy, isEqual } from "../../../Functions/ObjectFunctions";
import {
  Appointment,
  AppointmentDurations,
  DBAppointment,
  ListAppointment,
} from "./AppointmentTypes";

const AppointmentFunctions = {
  copy: <T extends Appointment | ListAppointment>(appointment: T): T => {
    return deepCopy(appointment);
  },

  export: <T extends Pick<Appointment, "durations">>(
    appointment: T
  ): Omit<T, "durations"> & Pick<DBAppointment, "durations"> => {
    return {
      ...appointment,
      durations: appointment.durations.map((d) => ({
        ...d,
        start: d.start.exportInt(),
        end: d.end.exportInt(),
      })),
    };
  },

  import: <T extends Pick<DBAppointment, "durations">>(
    appointment: T
  ): Omit<T, "durations"> & Pick<Appointment, "durations"> => {
    return {
      ...appointment,
      durations: appointment.durations.map((d) => ({
        ...d,
        start: SimpleDate.importInt(d.start),
        end: SimpleDate.importInt(d.end),
      })),
    };
  },

  isValid: (appointment: Appointment): boolean => {
    return Boolean(
      // Services are valid
      appointment.services.every(
        ({ serviceid }) => typeof serviceid === "number"
      ) &&
        appointment.services.length > 0 &&
        // Employee is valid -- or booked online
        (appointment.online || appointment.main_employeeid !== -1) &&
        // Other errors are not present
        appointment.durations.length > 0 &&
        appointment.price >= 0 &&
        AppointmentFunctions.getPrice(appointment) >= 0 &&
        // Customer is selected
        appointment.customerid
    );
  },

  isEqual: (a: Appointment, b: Appointment): boolean => {
    return isEqual(a, b);
  },

  getDurationString: (
    appointment: Appointment | AppointmentDurations[],
    options?: {
      includePreparation?: boolean;
      short?: boolean;
      lineBreaks?: boolean;
    }
  ): string => {
    options = {
      includePreparation: false,
      short: false,
      lineBreaks: true,
      ...options,
    };
    const durations = (
      Array.isArray(appointment)
        ? deepCopy(appointment)
        : AppointmentFunctions.copy(appointment).durations
    ).sort((a, b) => a.start.exportInt() - b.start.exportInt());

    if (options.short) {
      return (
        durations[0].start.getTimeString() +
        " - " +
        // get the last duration -> is not always the last duration in the array, since only sorted by start time
        durations
          .reduce(
            (acc, dur) => {
              if (dur.end.exportInt() > acc.exportInt()) return dur.end;
              return acc;
            },
            durations[durations.length - 1].end
          )
          .getTimeString()
      );
    }

    function getStringOfDuration(duration: AppointmentDurations) {
      return (
        (duration.preparation_time && options?.includePreparation
          ? " (" + +duration.preparation_time + "min)"
          : "") +
        duration.start.getTimeString() +
        " - " +
        duration.end.getTimeString() +
        (duration.follow_up_time && options?.includePreparation
          ? " (" + +duration.follow_up_time + "min)"
          : "")
      );
    }

    return durations
      .map((duration) => getStringOfDuration(duration))
      .join(options.lineBreaks ? "\n" : ", ");
  },

  moveDurations: (durations: AppointmentDurations[], start: SimpleDate) => {
    durations = durations.sort(
      (a, b) => a.start.exportInt() - b.start.exportInt()
    );

    const minDiff = getMinuteOfDay(start) - getMinuteOfDay(durations[0].start);
    const dayDiff = getDaysBetween(start, durations[0].start);

    return durations.map((duration) => ({
      ...duration,
      start: duration.start.add(minDiff, 0, dayDiff),
      end: duration.end.add(minDiff, 0, dayDiff),
    }));
  },

  getConflictingDurations: (durations: AppointmentDurations[]): number[] => {
    const conflicts: number[] = [];
    durations.forEach((d1, index) => {
      if (
        durations.some((d2, index2) => {
          if (index === index2) return false;

          let start1 = d1.start.copy();
          let end1 = d1.end.copy();
          let start2 = d2.start.copy();
          let end2 = d2.end.copy();

          // Duration is 0 minutes long
          if (start1.isEqual(end1) !== EqualityCheck.later) return true;

          // if employees are equal -> check if preparation and follow up time overlaps
          // otherwise only check if start and end time overlaps to not have customer at two places at the same time
          if (d1.employeeid === d2.employeeid) {
            start1 = start1.add(-d1.preparation_time);
            end1 = end1.add(d1.follow_up_time);
            start2 = start2.add(-d2.preparation_time);
            end2 = end2.add(d2.follow_up_time);
          }

          if (
            // start1 is between start2 and end2
            (start1.exportInt() > start2.exportInt() &&
              start1.exportInt() < end2.exportInt()) ||
            // start2 is between start1 and end1
            (end1.exportInt() > start2.exportInt() &&
              end1.exportInt() < end2.exportInt())
          )
            return true;

          return false;
        })
      )
        conflicts.push(index);
    });
    return conflicts;
  },

  hasConflictingDurations: (durations: AppointmentDurations[]): boolean => {
    return AppointmentFunctions.getConflictingDurations(durations).length > 0;
  },

  getPrice(appointment: Pick<Appointment, "price" | "services">): number {
    return (
      appointment.price +
      appointment.services.reduce((acc, s) => acc + s.price_difference, 0)
    );
  },

  isPaid(
    appointment: Pick<Appointment, "price" | "services" | "payments">
  ): boolean {
    return (
      appointment.payments.reduce((acc, p) => acc + p.amount, 0) >=
      AppointmentFunctions.getPrice(appointment)
    );
  },
};

export default AppointmentFunctions;
