import { Send } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import OptionalTooltip from "../../../Generic/OptionalTooltip";
import InputWrapper from "./InputWrapper";

export interface CustomerGroupSettingsProps {
  groups?: string[];
  onChange: (groups: string[]) => void;
}

export default function CustomerGroupSettings(
  props: CustomerGroupSettingsProps
) {
  const [name, setName] = React.useState<string>("");

  const disabled =
    name.trim().length === 0 || props.groups?.includes(name.trim());

  const addGroup = () => {
    if (disabled) return;
    props.onChange(
      [...(props.groups ?? []), name].sort((a, b) => a.localeCompare(b))
    );
    setName("");
  };

  const deleteConfirmRef = React.useRef<HTMLDivElement>(null!);
  const deleteConfirmGroup = React.useRef<string>("");
  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);

  return (
    <InputWrapper
      name="Kundengruppen"
      sx={{
        width: "90%",
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value.replaceAll(",", ""))}
        onKeyDown={(e) => e.key === "Enter" && addGroup()}
        slotProps={{
          input: {
            endAdornment: (
              <OptionalTooltip
                title={
                  disabled
                    ? name.trim().length === 0
                      ? "Der Name darf nicht leer sein"
                      : "Gruppe bereits vorhanden"
                    : undefined
                }
              >
                <span>
                  <IconButton
                    onClick={addGroup}
                    disabled={disabled}
                    color="primary"
                  >
                    <Send />
                  </IconButton>
                </span>
              </OptionalTooltip>
            ),
          },
        }}
      />

      <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
        {props.groups?.map((group) => (
          <Chip
            key={group}
            label={group}
            onDelete={(e) => {
              deleteConfirmRef.current = e.currentTarget as HTMLDivElement;
              deleteConfirmGroup.current = group;
              setDeleteConfirmOpen(true);
            }}
          />
        ))}
        <Popover
          open={deleteConfirmOpen}
          anchorEl={deleteConfirmRef.current}
          onClose={() => setDeleteConfirmOpen(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Box p={2}>
            <Typography variant="body1">
              Wenn du die Gruppe "{deleteConfirmGroup.current}" löschst, wird
              sie bei allen Kunden entfernt.
            </Typography>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  props.onChange(
                    (props.groups ?? []).filter(
                      (group) => group !== deleteConfirmGroup.current
                    )
                  );
                  setDeleteConfirmOpen(false);
                }}
              >
                Verstanden
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>
    </InputWrapper>
  );
}
