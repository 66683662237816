import React from "react";
import { ID, Styles } from "../../../Types";
import { Add, ArrowDropDown, ArrowDropUp, Edit } from "@mui/icons-material";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fab,
  IconButton,
  Box,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Tooltip,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomerServer from "../../Server/Customers/CustomerServer";
import EmployeeServer from "../../Server/Employees/EmployeeServer";
import { stringifyPrice } from "../../../Functions/functions";
import { useServer } from "../../Server/ServerContext";
import { useInView } from "react-intersection-observer";
import {
  CustomerSortBy,
  ListCustomer,
} from "../../Server/Customers/CustomerTypes";
import { SimpleDate } from "@idot-digital/calendar-api";
import useLoadMore from "../../../Hooks/LoadMore";
import TimePeriodPicker from "../Employees/TimePeriodPicker";

const styles: Styles = {
  createNew: {
    position: "absolute",
    float: "right",
    bottom: (theme) => theme.spacing(2),
    right: (theme) => theme.spacing(2),
  },
  tableWrapper: {
    padding: (theme) => theme.spacing(4),
    height: (theme) => `calc(100% - ${theme.spacing(4)} * 2)`,
    boxSizing: "border-box",
    overflow: "auto",
  },
  buttonWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    padding: (theme) => theme.spacing(2, 0),
  },
  search: {
    maxWidth: (theme) => theme.spacing(28),
    padding: (theme) => theme.spacing(2, 2, 0, 4),
  },
  wrapper: {
    height: "100%",
    overflow: "hidden",
  },
  center: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
};

export default function SettingsCustomers() {
  const navigate = useNavigate();
  const { settings } = useServer();
  const { ref, inView } = useInView();

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { data: employees = [], isSuccess: employeesLoaded } =
    EmployeeServer.useAll();

  const [defaultEmployeeid, setDefaultEmployeeid] = React.useState<ID>(-1);
  const employee = React.useMemo(
    () => employees.find((e) => e.id === defaultEmployeeid),
    [employees, defaultEmployeeid]
  );

  const [search, setSearch] = React.useState<string | null>(null);
  const [debouncedSearch, setDebouncedSearch] = React.useState<string | null>(
    null
  );
  const debouncedSearchTimeout = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    if (debouncedSearchTimeout.current)
      clearTimeout(debouncedSearchTimeout.current);
    debouncedSearchTimeout.current = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);
    return () => {
      if (debouncedSearchTimeout.current)
        clearTimeout(debouncedSearchTimeout.current);
    };
  }, [search]);

  const [period, setPeriod] = React.useState({
    start: SimpleDate.now().add(0, 0, -365).setHours(0, 0),
    end: SimpleDate.now().add(0, 0, 0, 1).setHours(23, 59),
  });

  const [sortBy, setSortBy] = React.useState<CustomerSortBy>("name");
  const [sortDirection, setSortDirection] = React.useState<"ASC" | "DESC">(
    "ASC"
  );

  const {
    data: customersRaw,
    fetchNextPage,
    isFetching,
    hasNextPage,
    isFetchedAfterMount: initialCustomersLoaded,
  } = CustomerServer.useInfinite({
    search: debouncedSearch ?? undefined,
    employeeID: employee?.id,
    small: false,
    sortby: sortBy,
    from: period.start,
    to: period.end,
    sortDirection,
  });

  const customers = React.useMemo(
    () => (customersRaw ? (customersRaw.pages.flat() as ListCustomer[]) : []),
    [customersRaw]
  );

  useLoadMore(fetchNextPage, inView, !hasNextPage);

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box
          display="flex"
          alignItems="flex-end"
          width="100%"
          px={4}
          py={2}
          gap={2}
          boxSizing="border-box"
        >
          <Box minWidth="max(12rem, 15%)">
            <TextField
              value={search || ""}
              onChange={(e) => setSearch(e.target.value || null)}
              onBlur={() => setDebouncedSearch(search)}
              label="Suche"
            />
          </Box>
          <Box minWidth="max(12rem, 15%)">
            <FormControl variant="standard" fullWidth>
              <InputLabel>Mitarbeiter</InputLabel>
              <Select
                value={defaultEmployeeid}
                onChange={async (e) => {
                  setDefaultEmployeeid(e.target.value as ID);
                }}
                fullWidth
              >
                <MenuItem value={-1} key={-1}>
                  <em>Keine Auswahl</em>
                </MenuItem>
                {employees.map((employee) => (
                  <MenuItem value={employee.id} key={employee.id}>
                    {employee.shortName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box minWidth="max(20rem, 30%)">
            <TimePeriodPicker
              period={period}
              onChange={(period) => {
                setPeriod(period);
              }}
              error={period.start.exportInt() > period.end.exportInt()}
            />
          </Box>
        </Box>
        <TableContainer sx={styles.tableWrapper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width={2}></TableCell>
                {(
                  [
                    {
                      label: "Name",
                      key: "name",
                    },
                    {
                      label: "Buchungen",
                      key: "number_of_appointments",
                    },
                    {
                      label: "Letzter Mitarbeiter",
                      key: "last_employee",
                    },
                    {
                      label: "Nächster Termin",
                      key: "next_appointment",
                    },
                    {
                      label: "Umsatz",
                      key: "revenue",
                    },
                    {
                      label: "pro Termin",
                      key: "revenue_per_appointment",
                    },
                    {
                      label: "pro Stunde",
                      key: "revenue_per_hour",
                    },
                  ] as const
                ).map((header, i) => (
                  <TableCell
                    key={header.key + i}
                    sx={{
                      cursor: "pointer",
                      fontWeight: sortBy === header.key ? "bold" : undefined,
                    }}
                    onClick={() => {
                      if (sortBy === header.key) {
                        setSortDirection(
                          sortDirection === "ASC" ? "DESC" : "ASC"
                        );
                      } else {
                        setSortBy(header.key);
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    {header.label}
                    {sortBy === header.key &&
                      (sortDirection === "ASC" ? (
                        <ArrowDropDown sx={{ verticalAlign: "middle" }} />
                      ) : (
                        <ArrowDropUp sx={{ verticalAlign: "middle" }} />
                      ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {initialCustomersLoaded &&
                employeesLoaded &&
                customers.map((customer) => (
                  <TableRow
                    key={customer.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ display: "flex" }}>
                      <Tooltip title="Bearbeiten">
                        <IconButton
                          onClick={() => navigate(customer.id.toString())}
                          color="primary"
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {customer.name}
                    </TableCell>
                    <TableCell>{customer.number_of_appointments}</TableCell>
                    <TableCell>
                      {employees.find(({ id }) => customer.last_employee === id)
                        ?.shortName || ""}
                    </TableCell>
                    <TableCell
                      sx={
                        customer.next_appointment &&
                        customer.next_appointment.exportInt() <
                          SimpleDate.now().exportInt()
                          ? {
                              opacity: 0.5,
                            }
                          : undefined
                      }
                    >
                      {customer.next_appointment?.getDateString()}
                    </TableCell>
                    <TableCell>
                      {stringifyPrice(
                        customer.revenue,
                        settings.getKey("currency_sign")
                      )}
                    </TableCell>
                    <TableCell>
                      {stringifyPrice(
                        customer.revenue_per_appointment,
                        settings.getKey("currency_sign")
                      )}
                    </TableCell>
                    <TableCell>
                      {stringifyPrice(
                        customer.revenue_per_hour,
                        settings.getKey("currency_sign")
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Box sx={styles.buttonWrapper} ref={ref}>
            {!hasNextPage && !isFetching ? (
              <Typography>Keine weiteren Nutzer verfügbar</Typography>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </TableContainer>
      </Box>

      <Fab color="primary" onClick={() => navigate("-1")} sx={styles.createNew}>
        <Add />
      </Fab>
    </>
  );
}
