import { deepCopy } from "../../../Functions/ObjectFunctions";
import Server from "../Generic/GenericServer";
import { flatObject } from "./Settings.functions";
import {
  BookingPeriod,
  DBSettings,
  DEFAULT_SETTINGS,
  Reminders,
  Settings,
  Texts,
} from "./SettingsTypes.d";

const SettingsServer = {
  convertSettingValueFromServer: (
    raw_settings: Partial<DBSettings>
  ): Settings => {
    const settings: Partial<Settings> = {};
    Object.entries(raw_settings).forEach(([key, value]) => {
      switch (key) {
        // setting was removed, but still in old namespcaces
        case "copy_email":
          break;
        case "agb_link":
        case "sms_sender_name":
        case "calendar_appointment_name":
        case "currency_sign":
        case "logo_src":
        case "reply_email":
        case "mail_sender_name":
          settings[key] = value;
          break;
        case "allowed_features":
        case "enabled_features":
        case "customer_groups":
          settings[key] = value === "" ? [] : value.split(",");
          break;
        case "max_employees":
        case "cancel":
          settings[key] = parseInt(value);
          break;
        case "default_view":
          if (value === "week" || value === "day") settings[key] = value;
          break;
        case "show_price_mode":
          if (
            value === "never" ||
            value === "summary" ||
            value === "always" ||
            value === "hide_additional"
          )
            settings[key] = value;
          break;
        default:
          const keys = key.split("-");
          let current = settings;
          for (let i = 0; i < keys.length; i++) {
            if (i === keys.length - 1) {
              // some of the inner values are booleans
              if (
                [
                  "reminders-middle-sms",
                  "reminders-early-sms",
                  "reminders-late-sms",
                  "reminders-middle-email",
                  "reminders-early-email",
                  "reminders-late-email",
                ].includes(key)
              )
                //@ts-ignore
                current[keys[i]] = value !== "false";
              else if (
                /^reminders-(early|middle|late)-offset-(minutes|days|weeks)$/.test(
                  key
                ) ||
                key.startsWith("booking_period")
              )
                //@ts-ignore
                current[keys[i]] = parseInt(value);
              //@ts-ignore
              else current[keys[i]] = value;
            } else {
              // build up object if it doesn't exist
              //@ts-ignore
              if (current[keys[i]] === undefined)
                //@ts-ignore
                current[keys[i]] = {};
              //@ts-ignore
              current = current[keys[i]];
            }
          }
      }
    });
    // check if some settings are not yet entered and set default values
    const defaultSettings: Settings = deepCopy(DEFAULT_SETTINGS);
    const unsetSettings: Partial<Settings> = {};
    Object.entries(defaultSettings).forEach(([key, value]) => {
      if (settings[key as keyof Settings] === undefined)
        unsetSettings[key as keyof Settings] = value;
    });
    if (Object.keys(unsetSettings).length > 0)
      SettingsServer.updateAll(unsetSettings);

    return { ...defaultSettings, ...settings };
  },

  convertSettingValueForServer: <T extends keyof Settings>(
    name: T,
    value: Settings[T]
  ): Partial<{ [key in keyof DBSettings]: string }> => {
    const settings: Partial<{ [key in keyof DBSettings]: string }> = {};
    switch (name) {
      case "agb_link":
      case "sms_sender_name":
      case "calendar_appointment_name":
      case "currency_sign":
      case "logo_src":
      case "reply_email":
      case "mail_sender_name":
        settings[name as keyof DBSettings] = value as string;
        break;
      case "allowed_features":
      case "enabled_features":
      case "customer_groups":
        settings[name as keyof DBSettings] = (value as string[]).join(",");
        break;
      case "max_employees":
      case "cancel":
        settings[name as keyof DBSettings] = value.toString();
        break;
      case "default_view":
        if (value === "week" || value === "day")
          settings[name as keyof DBSettings] = value as string;
        else
          settings[name as keyof DBSettings] = DEFAULT_SETTINGS[
            name as keyof Settings
          ] as string;
        break;
      case "show_price_mode":
        if (
          value === "never" ||
          value === "summary" ||
          value === "always" ||
          value === "hide_additional"
        )
          settings[name as keyof DBSettings] = value as string;
        else
          settings[name as keyof DBSettings] = DEFAULT_SETTINGS[
            name as keyof Settings
          ] as string;
        break;
      default:
        const obj = value as BookingPeriod | Reminders | Texts;
        Object.assign(settings, flatObject(obj, name + "-"));
    }
    return settings;
  },

  async get<T extends keyof Settings>(name: T): Promise<Settings[T]> {
    return await Server.get<Settings[T]>("settings/:name", {
      params: {
        name,
      },
      errorMessage: 'Fehler beim Laden der Einstellung "' + name + '"',
    });
  },

  async list(): Promise<Settings> {
    const settings = await Server.get<DBSettings>("settings", {
      errorMessage: "Fehler beim Laden der Einstellungen",
    });
    return SettingsServer.convertSettingValueFromServer(settings);
  },

  async update<Name extends keyof Settings>(
    name: Name,
    value: Settings[Name]
  ): Promise<void> {
    const updatedSettings = SettingsServer.convertSettingValueForServer(
      name,
      value
    );
    await Promise.all(
      Object.entries(updatedSettings).map(([key, value]) =>
        Server.put("settings/:key", {
          params: {
            key,
          },
          body: {
            value,
          },
          errorMessage: 'Fehler beim Speichern der Einstellung "' + key + '"',
        })
      )
    );
  },

  async updateAll(settings: Partial<Settings>): Promise<void> {
    await Promise.all(
      Object.entries(settings).map(([name, value]) =>
        this.update(name as keyof Settings, value)
      )
    );
  },
};

export default SettingsServer;
