import React from "react";
import { Routes, useParams, Route } from "react-router";
import { useServer } from "../../Server/ServerContext";
import EmployeeInfo from "./EmployeeInfo";
import EmployeePrice from "./EmployeePrice";
import config from "../../../config";
import FloatingLoadingButton from "../FloatingLoadingButton";
import { useNavigate } from "react-router-dom";
import { useSaveSettings } from "../SaveSettingsProvider";
import EmployeeWorkHours from "./EmployeeWorkHours";
import { SimpleDate, SimpleDuration } from "@idot-digital/calendar-api";
import Loading from "../../Loading/Loading";
import { Employee } from "../../Server/Employees/EmployeeTypes";
import EmployeeFunctions from "../../Server/Employees/Employee.functions";
import EmployeeServer from "../../Server/Employees/EmployeeServer";
import {
  DEFAULT_WORKHOURS,
  WeekWorkhours,
} from "../../Server/WorkingHours/WorkhoursTypes";
import { isCorrectDuration } from "../../Server/Generic/GenericTypes";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import DayPicker from "../../Calendar/DayPicker/DayPicker";
import { Delete as DeleteIcon } from "@mui/icons-material";
import PromiseButton from "../../Loading/PromiseButton";

export const EMPTY_EMPLOYEE: Employee = {
  id: -1,
  shortName: "",
  fullName: "",
  image: "",
  color: config.employeeColors[0].color,
  prices: {},
  workhours: Array.from({ length: 5 })
    .map(
      () =>
        new SimpleDuration(
          DEFAULT_WORKHOURS().start,
          DEFAULT_WORKHOURS().end
        ) as SimpleDuration | null
    )
    .concat(Array.from({ length: 2 }).map(() => null)),
  booking_time: Array.from({ length: 5 })
    .map(
      () =>
        new SimpleDuration(
          DEFAULT_WORKHOURS().start,
          DEFAULT_WORKHOURS().end
        ) as SimpleDuration | null
    )
    .concat(Array.from({ length: 2 }).map(() => null)),
  breaks: Array.from({ length: 7 }).map(() => null),
};

export default function EmployeeWrapper() {
  const id = parseInt(useParams()?.id || "-1");
  const navigate = useNavigate();
  const { settings } = useServer();
  const { useSetChanged } = useSaveSettings();

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { data: employees, isSuccess: employeesLoaded } =
    EmployeeServer.useAll();

  const { data: initialEmployee, isFetching: employeeLoading } =
    EmployeeServer.use(id);

  const {
    data: futureAppointments = null,
    isSuccess: futureAppointmentsLoaded,
  } = EmployeeServer.useFutureAppointments(id);

  const [employee, setEmployee] = React.useState(EMPTY_EMPLOYEE);

  // workhours need to be save while not being in duration bcs they throw an error when the input is invalid
  const [workHours, setWorkHours] = React.useState<WeekWorkhours>([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [bookingsHours, setBookingHours] = React.useState<WeekWorkhours>([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [breaks, setBreaks] = React.useState<WeekWorkhours>([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  // day until which the current workhours should remain the same
  // null: instantly
  // SimpleDate: until that date
  const [workhoursUntil, setWorkhoursUntil] = React.useState<SimpleDate>(
    SimpleDate.now().setHours(0, 0)
  );
  const [workhoursUntilOpen, setWorkhoursUntilOpen] = React.useState(false);

  // if dialog to confirm deletion is open
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  // redirect back if trying to create new employee but maximum is reached
  React.useEffect(() => {
    // redirect back if limit is reached
    if (
      id === -1 &&
      employeesLoaded &&
      employees &&
      employees.length >= settings.getKey("max_employees")
    )
      navigate("/settings/employees");
    // eslint-disable-next-line
  }, [employees, settings.getKey("max_employees"), id]);

  const [invalidWorkhourInput, setInvalidWorkhourInput] = React.useState(false);

  const hasWorkHoursError = React.useCallback(() => {
    if (workHours.some((hour) => hour && !isCorrectDuration(hour))) return true;
    if (bookingsHours.some((hour) => hour && !isCorrectDuration(hour)))
      return true;
    if (breaks.some((hour) => hour && !isCorrectDuration(hour))) return true;
    return invalidWorkhourInput;
  }, [workHours, bookingsHours, invalidWorkhourInput, breaks]);

  const error = React.useMemo(() => {
    if (!employee.shortName) return true;
    if (!employee.fullName) return true;
    if (hasWorkHoursError()) return true;
    return false;
  }, [employee, hasWorkHoursError]);
  // error includes work hours since they only get updated in employee state once they are valid
  const changed = React.useMemo(
    () =>
      !EmployeeFunctions.isEqual(employee, initialEmployee || EMPTY_EMPLOYEE),
    [employee, initialEmployee]
  );

  const workhoursChanged = React.useMemo(
    () =>
      !EmployeeFunctions.isEqualWorkhours(
        employee,
        initialEmployee || EMPTY_EMPLOYEE
      ),
    [employee, initialEmployee]
  );

  const save = async (startDate?: SimpleDate | null) => {
    // only open popup when workhours are changed and employee is not new
    if (workhoursChanged && startDate === undefined && id !== -1)
      return setWorkhoursUntilOpen(true);
    const newID = await EmployeeServer[
      employee.id === -1 ? "create" : "update"
    ](employee, startDate || SimpleDate.now());

    // go to employee page when employee gets created
    // createEmployee returns uuid and update return void
    if (newID) {
      navigate(window.location.pathname.replace("-1", newID.toString()));
    } else {
      // update employee to update changed memo
      setEmployee(EmployeeFunctions.copy(employee));
    }
  };

  const deleteEmployee = async () => {
    try {
      await EmployeeServer.delete(employee.id);

      navigate("/settings/employees");
    } catch (e) {
      console.error(e);
    }
  };

  useSetChanged({ error, changed }, { save });

  React.useEffect(() => {
    if (!hasWorkHoursError()) {
      setEmployee((employee) => {
        const newEmployee = EmployeeFunctions.copy(employee);
        newEmployee.workhours = workHours.map((dur) =>
          dur ? new SimpleDuration(dur.start, dur.end) : null
        );
        newEmployee.breaks = breaks.map((dur) =>
          dur ? new SimpleDuration(dur.start, dur.end) : null
        );
        newEmployee.booking_time = bookingsHours.map((dur) =>
          dur ? new SimpleDuration(dur.start, dur.end) : null
        );
        return newEmployee;
      });
    }
    // eslint-disable-next-line
  }, [hasWorkHoursError, workHours, breaks, bookingsHours]);

  React.useEffect(() => {
    if (initialEmployee && initialEmployee?.id !== employee.id) {
      const copyEmployee = EmployeeFunctions.copy(initialEmployee);
      setEmployee(copyEmployee);
      setBookingHours(
        copyEmployee.booking_time.map((dur) =>
          dur
            ? {
                start: dur.start,
                end: dur.end,
              }
            : null
        ) as WeekWorkhours
      );
      setBreaks(
        copyEmployee.breaks.map((dur) =>
          dur
            ? {
                start: dur.start,
                end: dur.end,
              }
            : null
        ) as WeekWorkhours
      );
      setWorkHours(
        copyEmployee.workhours.map((dur) =>
          dur
            ? {
                start: dur.start,
                end: dur.end,
              }
            : null
        ) as WeekWorkhours
      );
    }
  }, [initialEmployee, employee.id, setEmployee]);

  if (employeeLoading || id !== employee.id) return <Loading />;

  return (
    <Box display={employeeLoading || id !== employee.id ? "none" : "contents"}>
      <Routes>
        <Route
          path="info"
          element={
            <EmployeeInfo
              employee={employee}
              onChange={(employee) => setEmployee(employee)}
            />
          }
        />
        <Route
          path="price"
          element={
            <EmployeePrice
              employee={employee}
              onChange={(employee) => setEmployee(employee)}
            />
          }
        />
        <Route
          path="workhours"
          element={
            <EmployeeWorkHours
              workHours={workHours}
              booking_time={bookingsHours}
              breaks={breaks}
              onChange={(workHours, breaks, bookingHours, error) => {
                if (error) setInvalidWorkhourInput(error);
                setWorkHours(workHours);
                setBreaks(breaks);
                setBookingHours(bookingHours);
              }}
            />
          }
        />
      </Routes>

      <FloatingLoadingButton disabled={error || !changed} onClick={save} />
      {id !== -1 && (
        <FloatingLoadingButton
          disabled={
            !futureAppointmentsLoaded ||
            futureAppointments === null ||
            futureAppointments.length > 0
          }
          tooltip={
            !futureAppointmentsLoaded ||
            futureAppointments === null ||
            futureAppointments.length > 0 ? (
              <Typography variant="caption">
                Der Mitarbeiter hat noch Termine in der Zukunft. Diese müssen
                zuerst gelöscht werden.
                {futureAppointments?.map((a) => (
                  <React.Fragment key={a.id}>
                    <br />
                    {a.start.getDateTimeString(false, true, true)}
                  </React.Fragment>
                ))}
              </Typography>
            ) : undefined
          }
          onClick={() => setDeleteOpen(true)}
          index={1}
        >
          <DeleteIcon />
        </FloatingLoadingButton>
      )}

      <Dialog open={workhoursUntilOpen}>
        <DialogTitle>Arbeitszeiten Änderung</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ab wann sollen die neuen Arbeitszeiten gelten? <br />
            Ab diesem Datum werden ALLE (auch manuell eingetragene)
            Arbeitszeiten überschrieben. Lediglicht Urlaub, Fortbildung und
            Krankheit werden nicht überschrieben.
          </DialogContentText>
          <DayPicker
            value={workhoursUntil}
            onChange={(day) => {
              if (day.exportDayInt() === workhoursUntil.exportDayInt()) return;
              setWorkhoursUntil(day);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setWorkhoursUntilOpen(false);
            }}
            variant="outlined"
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              setWorkhoursUntilOpen(false);
              save(null);
            }}
            variant="contained"
          >
            Sofort
          </Button>
          <Button
            onClick={() => {
              setWorkhoursUntilOpen(false);
              save(workhoursUntil);
            }}
            variant="contained"
          >
            Ab dem {workhoursUntil.getDateString()}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteOpen}>
        <DialogTitle>Mitarbeiter löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchten Sie den Mitarbeiter wirklich löschen? Diese Aktion kann
            nicht rückgängig gemacht werden.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteOpen(false);
            }}
            variant="outlined"
          >
            Abbrechen
          </Button>
          <PromiseButton
            onClick={async () => {
              setDeleteOpen(false);
              await deleteEmployee();
            }}
            variant="contained"
          >
            Löschen
          </PromiseButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
