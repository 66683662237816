import { SimpleDate } from "@idot-digital/calendar-api";
import { queryClient } from "../../../queryClient";
import { ID } from "../../../Types";
import {
  AppointmentAttributes,
  EMPTY_APPOINTMENT,
} from "../Appointments/AppointmentTypes";
import Server from "../Generic/GenericServer";
import { PartialPick } from "../Generic/GenericTypes";
import {
  Customer,
  CustomerSortBy,
  FullCustomer,
  ListCustomer,
  LongestNames,
  SearchCustomer,
} from "./CustomerTypes";
import { useInfiniteQuery, useQueries, useQuery } from "@tanstack/react-query";

const CustomerServer = {
  get(id: ID) {
    return Server.get<FullCustomer>("/customers/:id", {
      params: {
        id,
      },
      errorMessage: "Fehler beim Laden des Kunden mit id " + id,
    });
  },

  getLongestNames() {
    return Server.get<LongestNames>("/customers/longest-name");
  },

  async list<S extends boolean | undefined>(
    cursor: ID | string | undefined,
    options?: {
      sortby?: CustomerSortBy;
      sortDirection?: "ASC" | "DESC";
      search?: string;
      employeeID?: number;
      from?: SimpleDate;
      to?: SimpleDate;
      small?: S;
    }
  ): Promise<
    S extends true
      ? { data: SearchCustomer[]; more: boolean }
      : {
          data: ListCustomer[];
          more: boolean;
        }
  > {
    const res = await Server.get<
      S extends true
        ? { data: SearchCustomer[]; more: boolean }
        : {
            data: (Omit<
              ListCustomer,
              | "next_appointment"
              | "revenue_per_appointment"
              | "revenue_per_hour"
            > & {
              next_appointment: number;
              total_appointment_time: number;
            })[];
            more: boolean;
          }
    >("/customers", {
      query: {
        cursor,
        ...options,
        from: options?.from?.exportInt(),
        to: options?.to?.exportInt(),
      },
      errorMessage: "Fehler beim Laden der Kunden",
    });

    // @ts-ignore
    if (options?.small) return res;

    return {
      more: res.more,
      data: res.data.map(
        // @ts-ignore
        (customer: ListCustomer & { total_appointment_time: number }) => ({
          ...customer,
          next_appointment:
            customer.next_appointment === null
              ? null
              : SimpleDate.importInt(customer.next_appointment),
        })
      ),
    } as any;
  },

  async create(customer: PartialPick<Customer, "id">) {
    const id = parseInt(
      await Server.post<string>("/customers", {
        body: customer,
        errorMessage: "Fehler beim Erstellen des Kunden",
      })
    );

    queryClient.invalidateQueries({
      queryKey: ["customer"],
      exact: false,
    });

    return id;
  },

  async update(customer: Customer) {
    await Server.patch<void>("/customers/:id", {
      params: {
        id: customer.id,
      },
      body: customer,
      errorMessage:
        "Fehler beim Aktualisieren des Kunden mit id " + customer.id,
    });
    queryClient.invalidateQueries({
      queryKey: ["customer"],
      exact: false,
    });
  },

  async delete(id: ID) {
    await Server.delete<void>("/customers/:id", {
      params: {
        id,
      },
      errorMessage: "Fehler beim Löschen des Kunden mit id " + id,
    });
    queryClient.invalidateQueries({
      queryKey: ["customer"],
      exact: false,
    });
  },

  async getLastAppointmentAttributes(id: ID) {
    const attributes = await Server.get<
      AppointmentAttributes | "No appointments found"
    >("/customers/:id/last_appointment_attributes", {
      params: {
        id,
      },
      // server returns 404 when this is the first appointment of a customer
      dontDisplayError: true,
    });
    if (attributes === null || typeof attributes === "string")
      return EMPTY_APPOINTMENT().attributes;
    return attributes;
  },

  async merge(id: ID, mergeid: ID) {
    await Server.delete<void>("/customers/merge/:id/:mergeid", {
      params: {
        id,
        mergeid,
      },
      errorMessage:
        "Fehler beim Zusammenführen von Kunden mit id " +
        id +
        " und " +
        mergeid,
    });
    queryClient.invalidateQueries({
      queryKey: ["customer"],
      exact: false,
    });
    queryClient.invalidateQueries({
      queryKey: ["appointment"],
      exact: false,
    });
  },

  async mergeMultiple(id: ID, mergeids: ID[]) {
    await Promise.all(
      mergeids.map((mergeid) => CustomerServer.merge(id, mergeid))
    );
  },

  async getLastPriceDifference(id: ID, serviceid: ID) {
    const priceDifference = await Server.get<number>(
      "/customers/:id/price_difference/:serviceid",
      {
        params: {
          id,
          serviceid,
        },
      }
    );
    return priceDifference;
  },

  async deleteGroups(groups: string[]) {
    if (!groups.length) return;
    await Server.post("/customers/delete_groups", {
      body: {
        groups,
      },
    });
  },

  getDuplicates(phone: string) {
    return Server.get<SearchCustomer[]>("/customers/duplicates/:phone", {
      params: {
        phone,
      },
      errorMessage:
        "Fehler beim Laden der Duplikate für die Telefonnummer " + phone,
    });
  },

  use(id: ID | undefined | null) {
    return useQuery({
      queryKey: ["customer", id],
      queryFn: () => CustomerServer.get(id as ID),
      enabled: !!id,
    });
  },

  useMultiple(ids: ID[]) {
    return useQueries({
      queries: ids.map((id) => ({
        queryKey: ["customer", id],
        queryFn: () => CustomerServer.get(id),
        enabled: !!id,
      })),
      combine: (res) => ({
        data: res
          .map((res) => res.data)
          .flat()
          .filter(Boolean) as Customer[],
        isSuccess: res.every((res) => res.isSuccess),
        isLoading: res.some((res) => res.isLoading),
        isError: res.some((res) => res.isError),
      }),
    });
  },

  useInfinite<S extends boolean | undefined>(filter: {
    sortby?: CustomerSortBy;
    sortDirection?: "ASC" | "DESC";
    search?: string;
    employeeID?: number;
    from?: SimpleDate;
    to?: SimpleDate;
    small?: S;
  }) {
    return useInfiniteQuery({
      queryKey: ["customer", filter],
      queryFn: async ({ pageParam }) =>
        (await CustomerServer.list<S>(pageParam, filter)).data,
      getNextPageParam: (lastPage) => {
        if (!lastPage) return -1;
        return (lastPage.at(-1) as ListCustomer | null)?.[
          filter.sortby ?? "id"
        ];
      },
      initialData: undefined,
      initialPageParam: undefined as undefined | string | number,
    });
  },

  useLongestName() {
    return useQuery({
      queryKey: ["customer", "longest-name"],
      queryFn: CustomerServer.getLongestNames,
    });
  },
};

export default CustomerServer;
