import React from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Styles } from "../../Types";
import { SimpleDate, SimpleDuration } from "@idot-digital/calendar-api";
import {
  AirplaneTicketRounded,
  Edit,
  HomeRounded,
  HotelRounded,
  LightbulbRounded,
} from "@mui/icons-material";
import WorkHoursPicker from "../Settings/Employees/WorkHoursPicker";
import PromiseButton from "../Loading/PromiseButton";
import { Employee, ListEmployee } from "../Server/Employees/EmployeeTypes.d";
import { Customer } from "../Server/Customers/CustomerTypes.d";
import {
  Appointment,
  AppointmentDurations,
} from "../Server/Appointments/AppointmentTypes";
import {
  DEFAULT_WORKHOURS,
  WorkhoursType,
  DayOff,
  ActualWorkhoursType,
  DEFAULT_BREAKS,
  AbsenseType,
  DEFAULT_BOOKING_TIME,
  DayWorkhours,
} from "../Server/WorkingHours/WorkhoursTypes";
import WorkhoursServer from "../Server/WorkingHours/WorkhoursServer";
import CustomerServer from "../Server/Customers/CustomerServer";
import WorkhourFunctions from "../Server/WorkingHours/Workhours.functions";
import { useSnackbar } from "notistack";
import { addDurations, subtractDuration } from "../../Functions/functions";
import AppointmentServer from "../Server/Appointments/AppointmentServer";
import EmployeeServer from "../Server/Employees/EmployeeServer";

export interface EmployeeStatusProps {
  employee: ListEmployee;
  employeeWorkhours: DayWorkhours | undefined;
  weekday: SimpleDate;
  darkBackground?: boolean;
}

interface ResolvedAppointment extends Appointment {
  employee: Employee | null;
  customer: Customer | null;
}

const styles = {
  wrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    background: "white",
    borderRadius: 1,
    position: "relative",
    "&:hover": {
      background: (theme) => theme.palette.secondary.dark,
    },
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  icon: {
    paddingRight: (theme) => theme.spacing(1),
  },
  tooltipWrapper: {
    position: "absolute",
    top: (theme) => theme.spacing(1),
    right: (theme) => theme.spacing(1),
  },
} satisfies Styles;

export default function EmployeeStatus(props: EmployeeStatusProps) {
  const { enqueueSnackbar } = useSnackbar();

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const [menuLoading, setMenuLoading] = React.useState(false);

  const [conflicts, setConflicts] = React.useState<ResolvedAppointment[]>([]);

  const [conflictedCustomers, setConflictedCustomer] = React.useState<
    Customer[]
  >([]);

  const [conflictsOpen, setConflictsOpen] = React.useState(false);
  const [statusKey, setStatusKey] = React.useState(0);

  const updateStatus = () => setStatusKey(statusKey + 1);

  const status = React.useMemo(() => {
    // No Data
    if (!props.employeeWorkhours) return "No Data";
    if (props.employeeWorkhours.absenses)
      return props.employeeWorkhours.absenses.type;
    if (!props.employeeWorkhours.workhour) return DayOff;
    return WorkhoursType.workhours;
  }, [props.employeeWorkhours]);

  const [workHoursOpen, setWorkHoursOpen] = React.useState(false);
  const [editWorkHours, setEditWorkHours] = React.useState(
    props.employeeWorkhours?.workhour || DEFAULT_WORKHOURS(props.weekday)
  );
  const [editBreak, setEditBreak] = React.useState(
    props.employeeWorkhours?.breakTime || null
  );
  const [editBookingTime, setEditBookingTime] = React.useState(
    props.employeeWorkhours?.bookingTime || null
  );

  // update states when getting new props
  React.useEffect(() => {
    if (props.employeeWorkhours?.workhour)
      setEditWorkHours(props.employeeWorkhours.workhour);
    if (props.employeeWorkhours?.breakTime)
      setEditBreak(props.employeeWorkhours.breakTime);
    if (props.employeeWorkhours?.bookingTime)
      setEditBookingTime(props.employeeWorkhours.bookingTime);
  }, [props.employeeWorkhours]);

  const editWorkHoursError = React.useMemo(
    () => WorkhourFunctions.hasError(editWorkHours),
    [editWorkHours]
  );
  const editBreakError = React.useMemo(
    () => !!editBreak && WorkhourFunctions.hasError(editBreak),
    [editBreak]
  );
  const editBookingTimeError = React.useMemo(
    () => !!editBookingTime && WorkhourFunctions.hasError(editBookingTime),
    [editBookingTime]
  );

  React.useEffect(() => {
    (async () => {
      setConflictedCustomer(
        (
          await Promise.all(
            conflicts.map((conflict) => {
              try {
                return conflict.customerid
                  ? CustomerServer.get(conflict.customerid)
                  : null;
              } catch (err) {
                return Promise.resolve(null);
              }
            })
          )
        ).filter(Boolean) as Customer[]
      );
    })();
    //eslint-disable-next-line
  }, [conflicts]);

  // looks for conflicts and opens the conflicts dialog
  // returns true if there are conflicts
  const handleConflicts = async (
    durations: Omit<
      AppointmentDurations,
      "follow_up_time" | "preparation_time" | "employeeid"
    >[]
  ): Promise<boolean> => {
    // only appointment conflicts are relevant here
    // conflict with other wotkhours can just be ignored
    const { appointments: conflicts } = await AppointmentServer.conflicts(
      durations.map((dur) => ({
        ...dur,
        preparation_time: 0,
        follow_up_time: 0,
        employeeid: props.employee.id,
      }))
    );
    if (conflicts.length === 0) return false;
    const resolvedConflicts: ResolvedAppointment[] = await Promise.all(
      // get appointments
      (await Promise.all(conflicts.map(AppointmentServer.get))).map(
        // resolve employee and customer
        async (conflict): Promise<ResolvedAppointment> => ({
          ...conflict,
          customer: conflict.customerid
            ? await CustomerServer.get(conflict.customerid)
            : null,
          employee:
            (await EmployeeServer.get(conflict.main_employeeid)) || null,
        })
      )
    );
    setConflicts(resolvedConflicts);
    setConflictsOpen(true);
    return true;
  };

  const setAbsense = async (type: ActualWorkhoursType | typeof DayOff) => {
    setMenuLoading(true);
    try {
      await WorkhoursServer.updateActual({
        absenses:
          type >= AbsenseType.vacation && type !== DayOff
            ? {
                type,
                start: props.weekday.copy().setHours(0, 0),
                end: props.weekday.copy().setHours(23, 59),
              }
            : null,
        breakTime:
          editBreak && type !== DayOff
            ? {
                start: editBreak.start,
                end: editBreak.end,
              }
            : null,
        bookingTime:
          editBookingTime && editBookingTime && type !== DayOff
            ? {
                start: editBookingTime.start,
                end: editBookingTime.end,
              }
            : null,
        day: props.weekday,
        employeeid: props.employee.id,
        workhour:
          type === DayOff
            ? null
            : {
                start: editWorkHours.start,
                end: editWorkHours.end,
              },
      });

      updateStatus();
      setContextMenu(null);
    } catch (e) {
      console.error(e);
    }
    setMenuLoading(false);
  };

  const removeAbsense = async () => {
    setMenuLoading(true);
    try {
      await WorkhoursServer.removeAbsense(props.employee.id, props.weekday);

      updateStatus();
      setContextMenu(null);
    } catch (e) {
      console.error(e);
    }
    setMenuLoading(false);
  };

  const resetWorkhours = async () => {
    if (
      props.weekday.copy().setHours(0, 0).exportInt() <
      SimpleDate.now().setHours(0, 0).exportInt()
    ) {
      enqueueSnackbar(
        "Arbeitszeiten in der Vergangenheit können nicht zurückgesetzt werden",
        {
          variant: "error",
        }
      );
      return;
    }

    setMenuLoading(true);
    try {
      await WorkhoursServer.resetActualWorkhours(
        props.employee.id,
        props.weekday
      );

      updateStatus();
      setContextMenu(null);
    } catch (e) {
      console.error(e);
    }
    setMenuLoading(false);
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const additionalContent =
    props.employeeWorkhours?.isRegular === false ? (
      <Tooltip
        title={"An diesem Tag wurden außerplanmäßige Arbeitszeiten eingetragen"}
      >
        <Box sx={styles.tooltipWrapper}>
          <Edit color="disabled" />
        </Box>
      </Tooltip>
    ) : (
      <></>
    );

  let label;
  switch (status) {
    case WorkhoursType.workhours:
      label = (
        <Box
          onClick={handleContextMenu}
          onContextMenu={handleContextMenu}
          sx={{
            ...styles.wrapper,
            ...(props.darkBackground && {
              background: (theme) => theme.palette.secondary.dark,
            }),
          }}
        >
          <Box sx={styles.textWrapper}>
            <Tooltip title="Arbeitszeiten">
              <Typography>
                {(props.employeeWorkhours?.breakTime
                  ? subtractDuration(
                      // props.employeeWorkhours.workhours is set since otherwise the status would be DayOff
                      new SimpleDuration(
                        props.employeeWorkhours!.workhour!.start,
                        props.employeeWorkhours!.workhour!.end
                      ),
                      new SimpleDuration(
                        props.employeeWorkhours!.breakTime.start,
                        props.employeeWorkhours!.breakTime.end
                      )
                    )
                  : [
                      new SimpleDuration(
                        props.employeeWorkhours!.workhour!.start,
                        props.employeeWorkhours!.workhour!.end
                      ),
                    ]
                )
                  .map((dur) => dur.getTimeString())
                  .join(", ")}
              </Typography>
            </Tooltip>
            <Tooltip title="Buchungszeit">
              <Typography variant="caption">
                {props.employeeWorkhours?.bookingTime
                  ? new SimpleDuration(
                      props.employeeWorkhours?.bookingTime.start,
                      props.employeeWorkhours?.bookingTime.end
                    ).getTimeString()
                  : ""}
              </Typography>
            </Tooltip>
          </Box>
          {additionalContent}
        </Box>
      );
      break;
    case AbsenseType.vacation:
      label = (
        <Box
          onClick={handleContextMenu}
          onContextMenu={handleContextMenu}
          sx={{
            ...styles.wrapper,
            ...{
              background: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <AirplaneTicketRounded sx={styles.icon} />
          <Typography>Urlaub</Typography>
          {additionalContent}
        </Box>
      );
      break;
    case AbsenseType.sick:
      label = (
        <Box
          onClick={handleContextMenu}
          onContextMenu={handleContextMenu}
          sx={{
            ...styles.wrapper,
            ...{
              background: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <HotelRounded sx={styles.icon} />
          <Typography>Krank</Typography>
          {additionalContent}
        </Box>
      );
      break;
    case AbsenseType.training:
      label = (
        <Box
          onClick={handleContextMenu}
          onContextMenu={handleContextMenu}
          sx={{
            ...styles.wrapper,
            ...{
              background: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <LightbulbRounded sx={styles.icon} />
          <Typography>Fortbildung/Berufsschule</Typography>
          {additionalContent}
        </Box>
      );
      break;
    case DayOff:
      label = (
        <Box
          sx={{
            ...styles.wrapper,
            ...{
              background: (theme) => theme.palette.secondary.main,
            },
          }}
          onClick={handleContextMenu}
          onContextMenu={handleContextMenu}
        >
          <HomeRounded sx={styles.icon} />
          <Typography>Frei</Typography>
          {additionalContent}
        </Box>
      );
      break;
    case "No Data":
    default:
      label = (
        <Box
          onClick={handleContextMenu}
          onContextMenu={handleContextMenu}
          sx={styles.wrapper}
        >
          <Typography>Keine Daten vorhanden</Typography>
          {additionalContent}
        </Box>
      );
  }

  return (
    <>
      {label}
      {props.employeeWorkhours && (
        <>
          <Menu
            open={contextMenu !== null}
            onClose={() => setContextMenu(null)}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            {menuLoading ? (
              <CircularProgress sx={{ padding: (theme) => theme.spacing(1) }} />
            ) : (
              // use array and no fragment since mui menu does not accept fragments as children
              [
                (status === DayOff || status === WorkhoursType.workhours) && (
                  <MenuItem
                    key="add_workhours"
                    onClick={() => {
                      setWorkHoursOpen(true);
                      setContextMenu(null);
                    }}
                  >
                    {status === DayOff
                      ? "Arbeitszeiten hinzufügen"
                      : "Zeiten ändern"}
                  </MenuItem>
                ),
                status !== "No Data" &&
                  status >= AbsenseType.vacation &&
                  status !== DayOff && (
                    <MenuItem
                      key="remove_absense"
                      onClick={() => {
                        removeAbsense();
                      }}
                    >
                      Abwesenheit entfernen
                    </MenuItem>
                  ),
                props.weekday.exportInt() >
                  SimpleDate.now().setHours(0, 0).exportInt() &&
                !props.employeeWorkhours.isRegular ? (
                  <MenuItem
                    onClick={() => resetWorkhours()}
                    key="reset_workhours"
                  >
                    Arbeitszeiten zurücksetzen
                  </MenuItem>
                ) : null,
                status === WorkhoursType.workhours ? (
                  <MenuItem
                    onClick={() => setAbsense(DayOff)}
                    key="remove_workhours"
                  >
                    Frei
                  </MenuItem>
                ) : null,
                status !== AbsenseType.vacation && status !== DayOff ? (
                  <MenuItem
                    onClick={() => {
                      setAbsense(AbsenseType.vacation);
                    }}
                    key="vacation"
                  >
                    Urlaub
                  </MenuItem>
                ) : null,
                status !== AbsenseType.sick && status !== DayOff ? (
                  <MenuItem
                    onClick={() => {
                      setAbsense(AbsenseType.sick);
                    }}
                    key="sick"
                  >
                    Krankheit
                  </MenuItem>
                ) : null,
                status !== AbsenseType.training && status !== DayOff ? (
                  <MenuItem
                    onClick={() => {
                      setAbsense(AbsenseType.training);
                    }}
                    key="training"
                  >
                    Fortbildung/Berufsschule
                  </MenuItem>
                ) : null,
              ]
            )}
          </Menu>
          <Dialog open={conflictsOpen} onClose={() => setConflictsOpen(false)}>
            <DialogTitle>Terminkonflikte</DialogTitle>
            <DialogContent>
              <Typography>
                Leider gibt es durch Ihre Änderung Terminkonflikte. Ihre
                Änderung wurden trotzdem ausgeführt. Folgende Termin
                überschneiden sich mit Ihrer Änderung:
              </Typography>
              <List>
                {conflicts.map((conflict, index) => (
                  <ListItem key={index}>
                    {new SimpleDuration(
                      conflict.durations[0].start,
                      conflict.durations[conflict.durations.length - 1].end
                    ).getDurationString() +
                      ", " +
                      conflictedCustomers.find(
                        (customer) => customer.id === conflict.customerid
                      )?.name ||
                      "gelöschter Kunde bei " + conflict.employee?.shortName ||
                      "gelöschter Mitabeiter"}
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button
                color="inherit"
                variant="contained"
                onClick={() => setConflictsOpen(false)}
              >
                Schließen
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={workHoursOpen}
            onClose={() => setWorkHoursOpen(false)}
            TransitionProps={{
              onEntering: () => {
                setEditWorkHours(
                  props.employeeWorkhours?.workhour ||
                    DEFAULT_WORKHOURS(props.weekday)
                );
                setEditBreak(props.employeeWorkhours?.breakTime || null);
              },
            }}
          >
            <DialogTitle>Arbeitszeiten festlegen</DialogTitle>
            <DialogContent>
              <Typography>
                Bitte geben Sie hier die neuen Arbeitszeiten für den{" "}
                {props.weekday.getDateString()} ein.
              </Typography>
              <Box
                display="grid"
                gridTemplateColumns="repeat(3, auto)"
                margin="0 auto"
                paddingTop={2}
                alignItems="center"
                justifyContent="center"
                textAlign="end"
                width="min-content"
              >
                <Typography>Arbeitszeit</Typography>
                <Box display="flex" alignItems="center">
                  <Checkbox sx={{ visibility: "hidden" }} />
                  <WorkHoursPicker
                    value={{
                      start: editWorkHours.start.toDate(),
                      end: editWorkHours.end.toDate(),
                    }}
                    onChange={(value) =>
                      setEditWorkHours({
                        ...editWorkHours,
                        start: SimpleDate.fromDate(value.start),
                        end: SimpleDate.fromDate(value.end),
                      })
                    }
                    error={editWorkHoursError}
                  />
                </Box>
                <Typography sx={{ visibility: "hidden" }}>
                  Arbeitszeit
                </Typography>
                <Typography>Pause</Typography>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={!!editBreak}
                    onClick={() => {
                      if (!editBreak)
                        setEditBreak(DEFAULT_BREAKS(props.weekday));
                      else setEditBreak(null);
                    }}
                  />
                  <WorkHoursPicker
                    disabled={!editBreak}
                    value={{
                      start: (
                        editBreak || DEFAULT_BREAKS(props.weekday)
                      ).start.toDate(),
                      end: (
                        editBreak || DEFAULT_BREAKS(props.weekday)
                      ).end.toDate(),
                    }}
                    onChange={(value) =>
                      setEditBreak({
                        ...(editBreak || DEFAULT_BREAKS(props.weekday)),
                        start: SimpleDate.fromDate(value.start),
                        end: SimpleDate.fromDate(value.end),
                      })
                    }
                    error={editBreakError}
                  />
                </Box>
                <Typography sx={{ visibility: "hidden" }}>Pause</Typography>
                <Typography>Buchungszeit</Typography>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={!!editBookingTime}
                    onClick={() => {
                      if (!editBookingTime)
                        setEditBookingTime(DEFAULT_BOOKING_TIME(props.weekday));
                      else setEditBookingTime(null);
                    }}
                  />
                  <WorkHoursPicker
                    disabled={!editBookingTime}
                    value={{
                      start: (
                        editBookingTime || DEFAULT_BOOKING_TIME(props.weekday)
                      ).start.toDate(),
                      end: (
                        editBookingTime || DEFAULT_BOOKING_TIME(props.weekday)
                      ).end.toDate(),
                    }}
                    onChange={(value) =>
                      setEditBookingTime({
                        ...(editBookingTime ||
                          DEFAULT_BOOKING_TIME(props.weekday)),
                        start: SimpleDate.fromDate(value.start),
                        end: SimpleDate.fromDate(value.end),
                      })
                    }
                    error={editBookingTimeError}
                  />
                </Box>
                <Typography sx={{ visibility: "hidden" }}>
                  Buchungszeit
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setWorkHoursOpen(false)}
                variant="contained"
              >
                Zurück
              </Button>
              <PromiseButton
                variant="contained"
                color="primary"
                disabled={editWorkHoursError || editBreakError}
                onClick={async () => {
                  const workhoursDuration = new SimpleDuration(
                    editWorkHours.start,
                    editWorkHours.end
                  );
                  const breakTime = editBreak
                    ? new SimpleDuration(editBreak.start, editBreak.end)
                    : null;
                  const notWorkinghours = workhoursDuration.invert();

                  const hasConflicts = await handleConflicts(
                    addDurations(notWorkinghours.concat(breakTime || [])).map(
                      (dur) => ({
                        start: dur.start,
                        end: dur.end,
                      })
                    )
                  );

                  if (hasConflicts) return;

                  // remove actual workhours -> reset to regular workhours
                  await WorkhoursServer.updateActual({
                    absenses: null,
                    breakTime: breakTime
                      ? {
                          start: breakTime.start,
                          end: breakTime.end,
                        }
                      : null,
                    bookingTime:
                      editBookingTime && editBookingTime
                        ? {
                            start: editBookingTime.start,
                            end: editBookingTime.end,
                          }
                        : null,
                    day: props.weekday,
                    employeeid: props.employee.id,
                    workhour: {
                      start: editWorkHours.start,
                      end: editWorkHours.end,
                    },
                  });

                  // close dialog
                  setWorkHoursOpen(false);
                  // update displayed workhours
                  updateStatus();
                }}
              >
                Speichern
              </PromiseButton>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
