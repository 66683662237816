export interface Account {
  // primary key - unique id
  username: string;
  displayname: string;
  permissions: Permission[];
  lastLoginTime: Date | null;
}

export enum Permission {
  statitics = "stats",
  admin = "admin",
  store_account = "store_account",
}

export interface JWTPayload extends Account {
  namespace: number;
}

export interface ListAccount extends Account {
  pin: string | null;
}
