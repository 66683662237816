import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { ManageAccounts } from "@mui/icons-material";

import CustomerTextFields from "../../../Settings/Customers/CustomerTextFields";
import { EMPTY_CUSTOMER } from "../../../Settings/Customers/EditCustomer";
import CustomerDuplicate from "../../../Settings/Customers/CustomerDuplicate";
import CustomerAttributes from "../../../Settings/Customers/CustomerAttributes";
import { isEqual } from "../../../../Functions/ObjectFunctions";
import { useAppointmentPopup } from "./AppointmentPopupContext";
import PromiseButton from "../../../Loading/PromiseButton";
import { AppointmentAttributes } from "../../../Server/Appointments/AppointmentTypes";
import {
  Customer,
  SearchCustomer,
} from "../../../Server/Customers/CustomerTypes";
import CustomerFunctions from "../../../Server/Customers/Customer.functions";
import CustomerServer from "../../../Server/Customers/CustomerServer";
import { ID } from "../../../../Types";
import OptionalTooltip from "../../../Generic/OptionalTooltip";

export interface EditCustomerProps {
  open: boolean;
  onClose: (open: boolean) => void;
  callback?: (id: ID, attributes: AppointmentAttributes) => void;
}

export const EMPTY_ATTRIBUTES: AppointmentAttributes = {
  coloration: [
    {
      color: "",
      nuance: "",
      strands: "",
      amount: "",
      hydrogen: "",
      exposure_time: "",
      preparation_time: "",
      follow_up_time: "",
      notes: "",
    },
    {
      color: "",
      nuance: "",
      strands: "",
      amount: "",
      hydrogen: "",
      exposure_time: "",
      preparation_time: "",
      follow_up_time: "",
      notes: "",
    },
    {
      color: "",
      nuance: "",
      strands: "",
      amount: "",
      hydrogen: "",
      exposure_time: "",
      preparation_time: "",
      follow_up_time: "",
      notes: "",
    },
  ],
  perm: [
    {
      exposure_time: "",
      product: "",
      winding_strength: "",
      temperature: "",
      preparation_time: "",
      notes: "",
    },
    {
      exposure_time: "",
      product: "",
      winding_strength: "",
      temperature: "",
      preparation_time: "",
      notes: "",
    },
    {
      exposure_time: "",
      product: "",
      winding_strength: "",
      temperature: "",
      preparation_time: "",
      notes: "",
    },
  ],
};

export default function AppointmentEditCustomer(props: EditCustomerProps) {
  const navigate = useNavigate();

  const appointmentPopup = useAppointmentPopup();

  const [customer, setCustomer] = React.useState<Customer>(EMPTY_CUSTOMER);
  const initialCustomer = React.useRef(customer);

  const [attributes, setAttributes] = React.useState(EMPTY_ATTRIBUTES);
  const initialAttributes = React.useRef(attributes);

  const [duplicates, setDuplicates] = React.useState<SearchCustomer[]>([]);

  const isNewCustomer = React.useMemo(() => customer.id === -1, [customer]);

  const changed = React.useMemo(
    () =>
      !CustomerFunctions.isEqual(customer, initialCustomer.current) ||
      !isEqual(attributes, initialAttributes.current),
    //eslint-disable-next-line
    [initialCustomer.current, customer, initialAttributes.current, attributes]
  );

  const errorKeys = React.useMemo(
    () => CustomerFunctions.isValid(customer),
    [customer]
  );

  const save = async () => {
    // check for duplicates before saving
    const duplicates = (
      await CustomerServer.getDuplicates(customer.phone)
    ).filter(({ id }) => id !== customer.id);
    setDuplicates(duplicates);

    if (duplicates.length) return;

    const id =
      await CustomerServer[isNewCustomer ? "create" : "update"](customer);

    // Update customer id (when new customer)
    if (id) {
      customer.id = id;
    }

    // Update initial states
    initialCustomer.current = { ...customer };
    initialAttributes.current = { ...attributes };

    props.callback?.(customer.id, attributes);
  };

  React.useEffect(() => {
    if (props.open) {
      const customer = appointmentPopup.customer || EMPTY_CUSTOMER;
      const attributes =
        appointmentPopup.appointment.attributes || EMPTY_ATTRIBUTES;

      setCustomer({ ...customer });
      setAttributes({ ...attributes });
      initialCustomer.current = { ...customer };
      initialAttributes.current = { ...attributes };
    }
    // Only update if customer id changes and open
    // Immidiate effect could result in missing data
    // eslint-disable-next-line
  }, [props.open, appointmentPopup.customer?.id]);

  const openUserSettings = () =>
    navigate(`/settings/customers/${customer?.id}`);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          if (!changed) props.onClose(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            {!isNewCustomer && (
              <IconButton onClick={openUserSettings} disabled={!customer}>
                <ManageAccounts />
              </IconButton>
            )}
            {isNewCustomer ? "Kunden erstellen" : "Kunden bearbeiten"}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={4}>
              <CustomerTextFields
                customer={customer}
                onChange={setCustomer}
                errorFields={errorKeys}
              />
            </Grid>
            <Grid item xs={8}>
              <CustomerAttributes
                attributes={attributes}
                onChange={setAttributes}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => props.onClose(false)}
          >
            Abbrechen
          </Button>
          <OptionalTooltip
            title={
              errorKeys.length > 0
                ? errorKeys
                    .map((key) => {
                      switch (key) {
                        case "name":
                          return "Name fehlt";
                        case "phone":
                          return "Telefonnummer fehlt";
                        case "email":
                          return "Ungültige E-Mail Adresse";
                        default:
                          return "Unbekannter Fehler";
                      }
                    })
                    .join(", ")
                : ""
            }
          >
            <span>
              <PromiseButton
                variant="contained"
                color="primary"
                disabled={errorKeys.length > 0 || !changed}
                onClick={save}
              >
                {isNewCustomer ? "Kunden erstellen" : "Speichern"}
              </PromiseButton>
            </span>
          </OptionalTooltip>
        </DialogActions>
      </Dialog>

      <CustomerDuplicate duplicates={duplicates} />
    </>
  );
}
