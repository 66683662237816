import { SimpleDate, SimpleDuration } from "@idot-digital/calendar-api";
import { deepCopy } from "../../../Functions/ObjectFunctions";
import { ID } from "../../../Types";
import { EMPTY_ATTRIBUTES } from "../../Calendar/Appointment/AppointmentPopup/AppointmentEditCustomer";
import { Account } from "../Accounts/AccountTypes";
import { AbsenseType } from "../WorkingHours/WorkhoursTypes";
import { PaymentMethod } from "../enums";

export interface DBAppointment {
  id: ID;
  customerid: ID | null;
  discountid: ID | null;
  main_employeeid: ID;
  price: number;
  online: boolean;
  notes: string;
  // username of account that created the appointment
  created_by: string | null;
  created_at: Date;
  // username of account that created the appointment
  cancelled_by: string | null;
  cancelled_at: Date | null;
  attributes: AppointmentAttributes;
  services: AppointmentService[];
  durations: DBAppointmentDurations[];
  manual_mode: boolean;
  payments: DBAppointmentPayment[];
}

export interface DBAppointmentDurations {
  employeeid: ID;
  start: number;
  end: number;
  preparation_time: number;
  follow_up_time: number;
}

export interface ListAppointment {
  id: ID;
  customerid: ID | null;
  discountid: ID | null;
  main_employeeid: ID;
  price: number;
  online: boolean;
  notes: string;
  // username of account that created the appointment
  created_by: string | null;
  created_at: Date;
  // username of account that created the appointment
  cancelled_by: string | null;
  cancelled_at: Date | null;
  attributes: AppointmentAttributes;
  services: AppointmentService[];
  durations: AppointmentDurations[];
  manual_mode: boolean;
  payments: AppointmentPayment[];
}

export interface Appointment extends ListAppointment {}

export interface AppointmentAttributes {
  perm: [Perm, Perm, Perm];
  coloration: [Coloration, Coloration, Coloration];
}

export interface Perm {
  winding_strength: string;
  product: string;
  exposure_time: string;
  temperature: string;
  preparation_time: string;
  notes: string;
}
export interface Coloration {
  color: string;
  nuance: string;
  strands: string;
  amount: string;
  hydrogen: string;
  exposure_time: string;
  preparation_time: string;
  follow_up_time: string;
  notes: string;
}

export interface DBAppointmentPayment {
  id: ID;
  appointmentid: ID;
  amount: number;
  payment_method: PaymentMethod;
  timestamp: string;
}
export interface AppointmentPayment
  extends Omit<DBAppointmentPayment, "timestamp"> {
  timestamp: Date;
}

export interface AppointmentService {
  serviceid: ID | null;
  price_difference: number;
}

export interface AppointmentDurations {
  employeeid: ID;
  start: SimpleDate;
  end: SimpleDate;
  preparation_time: number;
  follow_up_time: number;
}

export type SortDirection = "ASC" | "DESC";

export interface Price {
  default_price: number;
  discounted_price: number;
  discountid: ID | null;
}

/**
 * Stringigies AbsenseType enum
 * @param type AbsenseType enum to stringify
 * @returns String corresponding to given AbsenseType
 */
export function stringifyAbsenseType(type: AbsenseType): string {
  switch (type) {
    case AbsenseType.sick:
      return "Krankheit";
    case AbsenseType.training:
      return "Training";
    case AbsenseType.vacation:
      return "Urlaub";
  }
}

export const EMPTY_APPOINTMENT = (
  account?: Account | string | null,
  employeeid?: ID | null,
  durationDate: SimpleDate = SimpleDate.fromDate(
    new Date(new Date().setSeconds(0, 0))
  )
): Appointment => {
  const duration =
    durationDate.getMinute() < 30 || durationDate.getHour() < 23
      ? new SimpleDuration(durationDate.copy(), durationDate.copy().add(30))
      : new SimpleDuration(
          SimpleDate.fromValues(
            durationDate.getYear(),
            durationDate.getMonth(),
            durationDate.getDay(),
            23,
            29
          ),
          SimpleDate.fromValues(
            durationDate.getYear(),
            durationDate.getMonth(),
            durationDate.getDay(),
            23,
            59
          )
        );

  return {
    id: -1,
    durations: [
      {
        start: duration.getBeginning(),
        end: duration.getEnd(),
        employeeid: -1,
        follow_up_time: 0,
        preparation_time: 0,
      },
    ],
    created_at: new Date(),
    customerid: null,
    discountid: null,
    main_employeeid: employeeid || -1,
    notes: "",
    price: 0,
    online: false,
    created_by:
      typeof account === "string" ? account : account?.username || null,
    attributes: deepCopy(EMPTY_ATTRIBUTES),
    cancelled_at: null,
    cancelled_by: null,
    // paid_amount: 0,
    // payments: [],
    services: [],
    manual_mode: false,
    payments: [],
  };
};
