import { Task } from "../ServiceTypes";

class TaskHelper {
  static hasInvalidDurations(
    task: Pick<Task, "durations"> | Task["durations"]
  ): boolean {
    const durations = "durations" in task ? task.durations : task;
    // check if for any duration end is smaller than start
    for (let i = 0; i < durations.length; i += 2) {
      if (durations[i] >= durations[i + 1]) return true;
    }
    const durObjs = TaskHelper.convertDurationsToObject(durations);
    // check if any duration collides with another
    return durObjs.some((dur1, i1) =>
      durObjs.some(
        (dur2, i2) =>
          i1 !== i2 &&
          // start of dur2 is between start and end of dur1
          ((dur1.start <= dur2.start && dur1.end >= dur2.start) ||
            // end of dur2 is between start and end of dur1
            (dur1.start <= dur2.end && dur1.end >= dur2.end))
      )
    );
  }

  static doDurationsCollide(durations1: number[], durations2: number[]) {
    const dur1 = this.convertDurationsToObject(durations1);
    const dur2 = this.convertDurationsToObject(durations2);
    return dur1.some((timeslot1) =>
      dur2.some((timeslot2) => {
        if (
          timeslot1.start >= timeslot2.start &&
          timeslot1.start < timeslot2.end
        )
          return true;
        if (timeslot1.end > timeslot2.start && timeslot1.end <= timeslot2.end)
          return true;
        return false;
      })
    );
  }

  static convertDurationsToObject(
    durations: number[]
  ): { start: number; end: number }[] {
    const convertedDurations: { start: number; end: number }[] = [];
    for (let i = 0; i < durations.length; i += 2) {
      convertedDurations.push({ start: durations[i], end: durations[i + 1] });
    }
    return convertedDurations;
  }

  static convertDurationsToNumber(durations: { start: number; end: number }[]) {
    const convertedDurations: number[] = [];
    for (const duration of durations) {
      convertedDurations.push(duration.start, duration.end);
    }
    return convertedDurations;
  }

  static doTasksHaveErrors(tasks: Task[]): boolean {
    if (tasks.some((task) => TaskHelper.hasInvalidDurations(task))) return true;
    return false;
  }
}

export default TaskHelper;
