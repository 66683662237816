import { deepCopy, isEqual } from "../../../Functions/ObjectFunctions";
import { Customer } from "./CustomerTypes";
import { validate as emailValidator } from "email-validator";

const CustomerFunctions = {
  /**
   * Checks if two customers are equal
   * @param customer1 Customer 1
   * @param customer2 Customer 2
   * @returns If customers have equal data
   */
  isEqual(customer1: Customer, customer2: Customer): boolean {
    if (customer1.phone) {
      customer1 = deepCopy(customer1);
      customer1.phone = customer1.phone.replace(/\s/g, "");
    }
    if (customer2.phone) {
      customer2 = deepCopy(customer2);
      customer2.phone = customer2.phone.replace(/\s/g, "");
    }

    if (!customer1.notes) customer1.notes = null;
    if (!customer2.notes) customer2.notes = null;

    return isEqual(customer1, customer2);
  },

  /**
   * Check if properties of a customer are valid to save
   * @param customer to check
   * @returns fields that are invalid
   */
  isValid(customer: Customer): (keyof Customer)[] {
    const invalidFields: (keyof Customer)[] = [];
    if (!customer.name) invalidFields.push("name");
    if (!customer.phone) invalidFields.push("phone");
    if (customer.email && !emailValidator(customer.email))
      invalidFields.push("email");
    return invalidFields;
  },
};

export default CustomerFunctions;
