import { deepCopy, isEqual } from "../../../Functions/ObjectFunctions";
import { Service, Task } from "./ServiceTypes";
import TaskHelper from "./Tasks/TaskHelper";

const ServiceFunctions = {
  isEqual(service1: Service, service2: Service) {
    return isEqual(service1, service2);
  },

  getInvalidTasks(service: Pick<Service, "tasks"> | Service["tasks"]) {
    const tasks = "tasks" in service ? service.tasks : service;
    const invalidTasks: Task[] = tasks.filter((task) =>
      TaskHelper.hasInvalidDurations(task)
    );
    return invalidTasks;
  },

  isValid(service: Service):
    | {
        valid: true;
        error?: undefined;
      }
    | { valid: false; error: string } {
    if (service.tasks.length === 0) return { valid: false, error: "no_tasks" };
    if (ServiceFunctions.getInvalidTasks(service).length)
      return { valid: false, error: "invalid_tasks" };
    if (!service.name) return { valid: false, error: "no_name" };
    if (service.categoryid <= 0) return { valid: false, error: "no_category" };
    if (isNaN(service.factor)) return { valid: false, error: "no_factor" };
    return { valid: true };
  },

  copy(service: Service) {
    return deepCopy(service) as Service;
  },

  copyTasks(tasks: Task[]) {
    return deepCopy(tasks) as Task[];
  },
};

export default ServiceFunctions;
