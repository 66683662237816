import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import useLoadMore from "../../../Hooks/LoadMore";
import { useInView } from "react-intersection-observer";
import { stringifyPrice } from "../../../Functions/functions";
import { useServer } from "../../Server/ServerContext";
import { Delete } from "@mui/icons-material";
import { SimpleDate } from "@idot-digital/calendar-api";
import PromiseButton from "../../Loading/PromiseButton";
import React from "react";
import CouponServer from "../../Server/Coupons/CouponServer";
import { Coupon } from "../../Server/Coupons/CouponTypes";
import { stringifyPaymentMethod } from "../../Generic/PaymentMethodSelector";

export default function CouponList() {
  const { ref, inView } = useInView();
  const {
    data: coupons,
    fetchNextPage,
    isLoading,
    hasNextPage,
  } = CouponServer.useInfinite();
  useLoadMore(fetchNextPage, inView, !hasNextPage);

  const { settings } = useServer();

  const [openDeleteCoupon, setOpenDeleteCoupon] = React.useState(false);
  const [couponToDelete, setCouponToDelete] = React.useState<Coupon | null>(
    null
  );
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <List>
      {coupons?.map((c, i) => (
        <ListItem
          key={c.id}
          sx={{
            transition: (theme) => theme.transitions.create("background-color"),
            borderRadius: (theme) => theme.shape.borderRadius,
            "&:hover": {
              backgroundColor: (theme) => theme.palette.action.hover,
            },
          }}
          secondaryAction={
            <IconButton
              edge="end"
              onClick={() => {
                setCouponToDelete(c);
                setOpenDeleteCoupon(true);
              }}
            >
              <Delete />
            </IconButton>
          }
        >
          <ListItemText
            primary={`${stringifyPrice(c.amount, settings.getKey("currency_sign"))} | ${stringifyPaymentMethod(c.payment_method)}`}
            secondary={SimpleDate.fromDate(
              new Date(c.created_at)
            ).getDateTimeString()}
          />
        </ListItem>
      ))}
      <Box ref={ref}>
        {isLoading && hasNextPage && <CircularProgress />}
        {!hasNextPage && (
          <Typography textAlign="center">Keine weiteren Einträge</Typography>
        )}
      </Box>
      <Dialog
        open={openDeleteCoupon}
        onClose={() => setOpenDeleteCoupon(false)}
      >
        <DialogTitle>Verkauf löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchten Sie den Gutschein wirklich löschen? Diese Aktion kann nicht
            rückgängig gemacht werden.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteCoupon(false)}>Abbrechen</Button>
          <PromiseButton
            variant="contained"
            color="primary"
            disabled={!couponToDelete}
            onClick={async () => {
              if (!couponToDelete) return;
              await CouponServer.delete(couponToDelete);
              if (mounted.current) setOpenDeleteCoupon(false);
            }}
          >
            Löschen
          </PromiseButton>
        </DialogActions>
      </Dialog>
    </List>
  );
}
