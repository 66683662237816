import React from "react";
import { ID, Styles } from "../../../Types";
import {
  AvatarGroup,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import EmployeeServer from "../../Server/Employees/EmployeeServer";
import { ListEmployee } from "../../Server/Employees/EmployeeTypes";
import EmployeeAvatar from "./EmployeeAvatar";

export interface EmployeeSelectorProps {
  value: ID | null;
  onChange?: (employeeid: ID | null) => void;
  multiple?: false;
  showAvatars?: boolean;
  label?: string;
}

export interface EmployeeMultipleSelectorProps {
  value: ID[];
  onChange?: (employeeids: ID[]) => void;
  multiple: true;
  showAvatars?: boolean;
  label?: string;
}

const styles: Styles = {
  select: {
    width: "100%",
  },
  label: {
    color: (theme) => theme.palette.text.primary,
  },
};

export default function EmployeeSelector(
  props: (EmployeeSelectorProps | EmployeeMultipleSelectorProps) &
    Omit<SelectProps, "onChange" | "value" | "multiple" | "label">
) {
  const {
    onChange,
    value,
    multiple,
    showAvatars,
    label = "Mitarbeiter",
    ...rest
  } = props;
  const { data: employees, isSuccess: employeesLoaded } =
    EmployeeServer.useAll();
  if (!employeesLoaded || !employees) return null;
  return (
    <FormControl variant="standard" sx={styles.select}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value}
        onChange={(e) => {
          onChange?.(e.target.value as any);
        }}
        fullWidth
        displayEmpty
        multiple={multiple}
        renderValue={(value) => {
          if (Array.isArray(value)) {
            if (value.length) {
              if (showAvatars) {
                return (
                  <AvatarGroup
                    max={4}
                    sx={{ justifyContent: "flex-end", mr: 1 }}
                    slotProps={{
                      surplus: {
                        sx: {
                          height: "1.5em",
                          width: "1.5em",
                          fontSize: "0.75em",
                        },
                      },
                    }}
                  >
                    {(
                      value
                        .map((id) => employees.find((e) => e.id === id))
                        .filter(Boolean) as ListEmployee[]
                    ).map((e) => (
                      <EmployeeAvatar
                        employee={e}
                        key={e.id}
                        sx={{
                          height: "1.5em",
                          width: "1.5em",
                          fontSize: "0.75em",
                        }}
                      />
                    ))}
                  </AvatarGroup>
                );
              } else {
                return value
                  .map((id) => employees.find((e) => e.id === id)?.shortName)
                  .join(", ");
              }
            } else return "";
          }

          return employees.find((e) => e.id === value)?.shortName || "";
        }}
        {...rest}
      >
        {employees.map((employee) => (
          <MenuItem value={employee.id} key={employee.id}>
            {Array.isArray(value) ? (
              <Checkbox checked={value.includes(employee.id)} />
            ) : (
              <EmployeeAvatar
                employee={employee}
                key={employee.id}
                sx={{ mr: 1 }}
              />
            )}
            <ListItemText primary={employee.shortName} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
