import React from "react";
import { useServer } from "../../../Server/ServerContext";
import AGBLink from "./AGBLink";
import FloatingLoadingButton from "../../FloatingLoadingButton";
import { isEqual } from "../../../../Functions/ObjectFunctions";
import BookingPeriodPicker from "./BookingPeriodPicker";
import { useSaveSettings } from "../../SaveSettingsProvider";
import CancelSetting from "./CancelSetting";
import DefaultViewSetting from "./DefaultViewSetting";
import InputWrapper from "./InputWrapper";
import { Box, TextField } from "@mui/material";
import {
  BookingPeriod,
  Settings,
} from "../../../Server/Settings/SettingsTypes";
import CurrencySettings from "./CurrencySettings";
import LogoSetting from "./LogoSetting";
import PriceDisplaySettings from "./PriceDisplaySettings";
import CustomerGroupSettings from "./CustomerGroupSettings";
import { Objects } from "@idot-digital/generic-helpers";
import CustomerServer from "../../../Server/Customers/CustomerServer";

export default function GeneralSettings() {
  const { settings: serverSettings } = useServer();
  const { useSetChanged } = useSaveSettings();

  //@ts-ignore
  window.settings = serverSettings;

  const [settings, setSettings] = React.useState(
    serverSettings.getValue() as Settings
  );

  const [reloadKey, reload] = React.useReducer(() => ({}), {});

  React.useEffect(() => {
    if (
      serverSettings.getKey("booking_period") !== null &&
      serverSettings.getKey("cancel") !== null
    ) {
      setSettings(serverSettings.getValue() as Settings);
    }
    //eslint-disable-next-line
  }, [serverSettings, reloadKey]);

  const changed = !isEqual(serverSettings.getValue(), settings);

  const save = async () => {
    const deletedGroups =
      serverSettings
        .getKey("customer_groups")
        ?.filter((group) => !settings.customer_groups.includes(group)) ?? [];
    await CustomerServer.deleteGroups(deletedGroups);
    await serverSettings.set({
      ...(serverSettings.getKey("agb_link") !== settings.agb_link && {
        agb_link: settings.agb_link,
      }),
      ...(serverSettings.getKey("booking_period") !==
        settings.booking_period && {
        booking_period: settings.booking_period,
      }),
      ...(serverSettings.getKey("cancel") !== settings.cancel && {
        cancel: settings.cancel,
      }),
      ...(serverSettings.getKey("default_view") !== settings.default_view && {
        default_view: settings.default_view,
      }),
      ...(serverSettings.getKey("calendar_appointment_name") !==
        settings.calendar_appointment_name && {
        calendar_appointment_name: settings.calendar_appointment_name,
      }),
      ...(serverSettings.getKey("currency_sign") !== settings.currency_sign && {
        currency_sign: settings.currency_sign,
      }),
      ...(serverSettings.getKey("logo_src") !== settings.logo_src && {
        logo_src: settings.logo_src,
      }),
      ...(serverSettings.getKey("show_price_mode") !==
        settings.show_price_mode && {
        show_price_mode: settings.show_price_mode,
      }),
      ...(!Objects.deepEqual(
        serverSettings.getKey("customer_groups"),
        settings.customer_groups
      ) && {
        customer_groups: settings.customer_groups,
      }),
    });
    reload();
  };

  useSetChanged({ error: false, changed }, { save });

  return (
    <Box>
      <Box
        display="flex"
        alignItems="flex-start"
        width="90%"
        sx={{ margin: (theme) => theme.spacing(0, 4), ">*": { flex: "1 0 0" } }}
      >
        <DefaultViewSetting
          view={settings.default_view}
          onChange={(default_view) =>
            setSettings({ ...settings, default_view })
          }
        />
        <CurrencySettings
          currencySign={settings.currency_sign}
          onChange={(currency_sign) =>
            setSettings({ ...settings, currency_sign })
          }
        />
        <CancelSetting
          cancel={settings.cancel}
          onChange={(cancel) => setSettings({ ...settings, cancel })}
        />
        <LogoSetting
          logo_src={settings.logo_src}
          onChange={(logo_src) => setSettings({ ...settings, logo_src })}
        />
        <PriceDisplaySettings
          onChange={(show_price_mode) =>
            setSettings({ ...settings, show_price_mode })
          }
          show_price_mode={settings.show_price_mode}
        />
      </Box>
      <BookingPeriodPicker
        period={settings.booking_period as BookingPeriod}
        onChange={(booking_period) =>
          setSettings({ ...settings, booking_period })
        }
      />
      <AGBLink
        link={settings.agb_link}
        onChange={(agb_link) => setSettings({ ...settings, agb_link })}
      />
      <InputWrapper
        name="Kalendar Terminname"
        sx={{
          width: "90%",
        }}
      >
        <TextField
          placeholder="Friseuer Termin bei ..."
          variant="outlined"
          value={settings.calendar_appointment_name}
          onChange={(e) =>
            setSettings({
              ...settings,
              calendar_appointment_name: e.target.value,
            })
          }
          fullWidth
        />
      </InputWrapper>
      <CustomerGroupSettings
        groups={settings.customer_groups}
        onChange={(customer_groups) =>
          setSettings({ ...settings, customer_groups })
        }
      />
      <FloatingLoadingButton disabled={!changed} onClick={save} />
    </Box>
  );
}
