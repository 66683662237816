import React from "react";
import { ID, Styles } from "../../../Types";
import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TimePeriodPicker from "../Employees/TimePeriodPicker";
import { SimpleDate } from "@idot-digital/calendar-api";
import Loading from "../../Loading/Loading";
import { ListAppointment } from "../../Server/Appointments/AppointmentTypes";
import { ListEmployee } from "../../Server/Employees/EmployeeTypes";
import AppointmentServer from "../../Server/Appointments/AppointmentServer";
import CustomerBookingItem from "./CustomerBookingItem";
import EmployeeServer from "../../Server/Employees/EmployeeServer";

export enum CanceledFilter {
  canceled,
  notcanceled,
  both,
}

export interface CustomerBookingsProps {
  customerid: ID;
  onSelect: (booking: ListAppointment | null) => void;
}

const styles: Styles = {
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: (theme) => theme.spacing(4),
    boxSizing: "border-box",
  },
  filterWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "> *": {
      margin: (theme) => theme.spacing(0, 1),
    },
  },
  datePickerWrapper: {
    flexGrow: 1,
  },
  employeePickerWrapper: {},
  employeePicker: {
    width: "100%",
    height: "3rem",
    borderRadius: "1.5rem",
    textAlign: "center",
  },
  paidPickerWrapper: {},
  tableHead: {
    color: (theme) => theme.palette.primary.main,
  },
  buttonWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    padding: (theme) => theme.spacing(2, 0),
  },
};

export default function CustomerBookings(props: CustomerBookingsProps) {
  const [period, setPeriod] = React.useState({
    start: SimpleDate.now().setHours(0, 0).add(0, 0, -365),

    end: SimpleDate.now().setHours(23, 59).add(0, 0, 0, 2),
  });

  const [selectedEmployees, setSelectedEmployees] = React.useState<
    ListEmployee[]
  >([]);

  const { data: employees = [], isSuccess: employeesLoaded } =
    EmployeeServer.useAll();
  React.useEffect(() => {
    if (employeesLoaded) setSelectedEmployees(employees);
  }, [employees, employeesLoaded]);

  const { data: appointments, isSuccess: appointmentLoaded } =
    AppointmentServer.useCustomerAppointments(
      props.customerid,
      period.start,
      period.end,
      employeesLoaded ? selectedEmployees.map((e) => e.id) : undefined
    );

  const [canceledFilter, setCanceledFilter] = React.useState(
    CanceledFilter.both
  );

  const [selectedBookingId, setSelectedBookingId] = React.useState<ID | null>(
    null
  );

  const filterFunction = (booking: ListAppointment): boolean => {
    if (
      canceledFilter === CanceledFilter.canceled &&
      booking.cancelled_at !== null
    )
      return false;
    if (
      canceledFilter === CanceledFilter.notcanceled &&
      booking.cancelled_at === null
    )
      return false;

    if (
      // don't filter for employees if all are selected -> don't filter out appointments of deleted employees when all are selected
      employeesLoaded &&
      selectedEmployees.length <= employees.length &&
      !selectedEmployees.some(
        (employee) => employee.id === booking.main_employeeid
      )
    )
      return false;

    return true;
  };

  const filteredAppointments = React.useMemo(
    () =>
      (appointments || [])
        .filter(filterFunction)
        .sort(
          (a, b) =>
            b.durations[0].start.exportInt() - a.durations[0].start.exportInt()
        ),
    //eslint-disable-next-line
    [appointments, selectedEmployees, period, canceledFilter]
  );

  React.useEffect(() => {
    const newBooking = filteredAppointments[0] || null;

    // select first non empty or first empty
    // deselect booking if it not displayed anymore
    if (
      !selectedBookingId ||
      !filteredAppointments.some((booking) => booking.id === selectedBookingId)
    ) {
      setSelectedBookingId(newBooking?.id || null);
      props.onSelect(newBooking);
    }
    //eslint-disable-next-line
  }, [filteredAppointments]);

  if (!employeesLoaded || !appointmentLoaded) return <Loading />;

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.filterWrapper}>
        <Box sx={styles.datePickerWrapper}>
          <TimePeriodPicker
            period={period}
            onChange={(period) => {
              setPeriod(period);
            }}
          />
        </Box>
        <Box sx={styles.paidPickerWrapper}>
          <FormControl fullWidth sx={{ width: "12rem" }}>
            <Select
              value={canceledFilter}
              onChange={(e) => {
                if (typeof e.target.value !== "string")
                  setCanceledFilter(e.target.value);
              }}
              input={<OutlinedInput />}
              renderValue={(type) =>
                type === CanceledFilter.both
                  ? "Alle"
                  : type === CanceledFilter.canceled
                    ? "Abgesagt"
                    : "Nicht abgesagt"
              }
              fullWidth
              sx={styles.employeePicker}
            >
              {[
                CanceledFilter.both,
                CanceledFilter.canceled,
                CanceledFilter.notcanceled,
              ].map((type, index) => (
                <MenuItem key={index} value={type}>
                  <ListItemText
                    primary={
                      type === CanceledFilter.both
                        ? "Alle"
                        : type === CanceledFilter.canceled
                          ? "Abgesagt"
                          : "Nicht abgesagt"
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={styles.employeePickerWrapper}>
          <FormControl fullWidth sx={{ width: "12rem" }}>
            <Select
              multiple
              value={selectedEmployees.map((employee) => employee.id)}
              onChange={(e) => {
                if (typeof e.target.value !== "string")
                  setSelectedEmployees(
                    employees.filter((employee) =>
                      (e.target.value as ID[]).includes(employee.id)
                    )
                  );
              }}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length >= employees.length) return "Alle";
                return selected
                  .map(
                    (id) =>
                      employees.find((employee) => employee.id === id)
                        ?.shortName
                  )
                  .join(", ");
              }}
              fullWidth
              sx={styles.employeePicker}
            >
              {employees.map((employee) => (
                <MenuItem key={employee.id} value={employee.id}>
                  <Checkbox
                    checked={selectedEmployees.some(
                      (employee1) => employee1.id === employee.id
                    )}
                  />
                  <ListItemText primary={employee.shortName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tabelHead} width="40%">
                <Typography sx={styles.tableHead} variant="h6">
                  Termine
                </Typography>
              </TableCell>
              <TableCell sx={styles.tabelHead} width="20%">
                <Typography sx={styles.tableHead} variant="h6">
                  bei
                </Typography>
              </TableCell>
              <TableCell sx={styles.tabelHead} width="5%">
                <Typography sx={styles.tableHead} variant="h6">
                  Datum
                </Typography>
              </TableCell>
              <TableCell sx={styles.tabelHead} width="5%">
                <Typography sx={styles.tableHead} variant="h6">
                  Betrag
                </Typography>
              </TableCell>
              <TableCell sx={styles.tabelHead} width="5%">
                <Typography sx={styles.tableHead} variant="h6">
                  Abgesagt
                </Typography>
              </TableCell>
              <TableCell sx={styles.tabelHead} width="25%">
                <Typography sx={styles.tableHead} variant="h6">
                  Gebucht am
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAppointments.map((booking, index) => (
              <CustomerBookingItem
                selected={booking.id === selectedBookingId}
                onClick={() => {
                  setSelectedBookingId(booking.id);
                  props.onSelect(booking);
                }}
                key={index}
                appointment={booking}
              />
            ))}
          </TableBody>
        </Table>
        <Box sx={styles.buttonWrapper}>
          <Typography>Keine weiteren Buchungen verfügbar</Typography>
        </Box>
      </TableContainer>
    </Box>
  );
}
