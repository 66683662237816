import React from "react";
import { AppointmentService } from "../../../Server/Appointments/AppointmentTypes";
import { Service } from "../../../Server/Services/ServiceTypes";
import { Percent, RestartAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { stringifyPrice } from "../../../../Functions/functions";
import { Styles } from "../../../../Types";
import NumberInput from "../../../Generic/NumberInput";
import { useServer } from "../../../Server/ServerContext";
import { useAppointmentPopup } from "./AppointmentPopupContext";

export interface AppointmentPricePickerProps {
  main_services: Service[];
  additional_services: Service[];
  price_differences: AppointmentService[];
  onChange: (services: AppointmentService[]) => void;
  toggleDiscount: () => void;
}

export const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "85%",
    mt: 0,
  },
  priceDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  priceWrapper: {
    display: "flex",
    alignItems: "center",
  },
  discountWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  serviceWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 0.5,
  },
  serviceName: {
    marginRight: (theme) => theme.spacing(1),
  },
  input: {
    flex: "0 0 4rem",
  },
} satisfies Styles;

export default function AppointmentPricePicker(
  props: AppointmentPricePickerProps
) {
  const {
    defaultPrice,
    totalPrice,
    price,
    appointment,
    isNew,
    setAppointmentData,
  } = useAppointmentPopup();
  const { settings } = useServer();

  // sort many service with accopanying additional services and price differences
  const services = React.useMemo(() => {
    const services = props.main_services.map((service) => ({
      service,
      price_difference:
        props.price_differences.find(
          ({ serviceid }) => serviceid === service.id
        )?.price_difference || 0,
      additional_services: [] as {
        service: Service;
        price_difference: number;
      }[],
    }));
    props.additional_services.forEach((service) => {
      const mainService = services.find(
        ({ service: { additional_serviceids } }) =>
          additional_serviceids.includes(service.id)
      );
      if (mainService)
        mainService.additional_services.push({
          service,
          price_difference:
            props.price_differences.find(
              ({ serviceid }) => serviceid === service.id
            )?.price_difference || 0,
        });
      else
        services.push({
          service,
          price_difference: 0,
          additional_services: [],
        });
    });
    return services;
  }, [props.additional_services, props.main_services, props.price_differences]);

  const resetPricePopoverAnchorEl = React.useRef<HTMLButtonElement | null>(
    null
  );
  const [resetPricePopoverOpen, setResetPricePopoverOpen] =
    React.useState(false);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.priceDisplay}>
        <Typography>Grundpreis:</Typography>
        <Box display="flex" alignItems="center" gap={1}>
          <Tooltip title="Grundpreis dieses Termins">
            <Typography>
              {stringifyPrice(
                isNew
                  ? appointment.discountid
                    ? defaultPrice.discounted_price
                    : defaultPrice.default_price
                  : (price ?? 0),
                settings.getKey("currency_sign")
              )}
            </Typography>
          </Tooltip>
          {isNew && (
            <Tooltip title="Standardpreis für diesen Termin">
              {defaultPrice.default_price !== defaultPrice.discounted_price ? (
                <>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ mr: -1, ml: 1 }}
                  />
                  <Box sx={styles.priceWrapper}>
                    <Tooltip
                      title={`Rabatt ${appointment.discountid ? "entfernen" : "hinzufügen"}`}
                    >
                      <IconButton
                        onClick={props.toggleDiscount}
                        color={!appointment.discountid ? "default" : "primary"}
                      >
                        <Percent />
                      </IconButton>
                    </Tooltip>
                    <Box sx={styles.discountWrapper}>
                      <Typography variant="caption">
                        {stringifyPrice(
                          defaultPrice.default_price,
                          settings.getKey("currency_sign")
                        )}
                      </Typography>
                      <Typography variant="caption">
                        {stringifyPrice(
                          defaultPrice.discounted_price,
                          settings.getKey("currency_sign")
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : price !== defaultPrice.default_price ? (
                <Typography variant="body1">
                  {"(" +
                    stringifyPrice(
                      defaultPrice.default_price,
                      settings.getKey("currency_sign")
                    ) +
                    ")"}
                </Typography>
              ) : (
                <span></span>
              )}
            </Tooltip>
          )}
          {!isNew && (
            <>
              {appointment.discountid ? (
                <Tooltip title="Preis zurücksetzen (Dieser Termin wurde mit Rabatt gebucht)">
                  <IconButton
                    ref={resetPricePopoverAnchorEl}
                    onClick={() => setResetPricePopoverOpen(true)}
                  >
                    <Percent color="primary" />
                  </IconButton>
                </Tooltip>
              ) : (
                price !== defaultPrice.default_price && (
                  <Tooltip title="Preis zurücksetzen">
                    <IconButton
                      ref={resetPricePopoverAnchorEl}
                      onClick={() => setResetPricePopoverOpen(true)}
                    >
                      <RestartAlt color="action" />
                    </IconButton>
                  </Tooltip>
                )
              )}
              <Popover
                anchorEl={resetPricePopoverAnchorEl.current}
                open={resetPricePopoverOpen}
                onClose={() => setResetPricePopoverOpen(false)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box p={2} maxWidth={600}>
                  <Typography variant="h6">Preis zurücksetzen</Typography>
                  <Typography>
                    Wollen Sie den Preis für diesen Termin zurücksetzen? Hierbei
                    wird ein potentieller Rabatt entfernt und anhand der
                    aktuellen Preise der Leistungen der Preis neu berechnet.
                  </Typography>
                  <Box
                    display="flex"
                    width="100%"
                    justifyItems="flex-end"
                    gap={1}
                    sx={{ float: "right" }}
                  >
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={() => setResetPricePopoverOpen(false)}
                    >
                      Abbrechen
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={
                        () => setAppointmentData({ price: 0, discountid: null }) // this cues a recalculation of the appointment price
                      }
                    >
                      Preis zurücksetzen
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </>
          )}
        </Box>
      </Box>
      <Divider
        orientation="horizontal"
        sx={{ width: "100%", borderStyle: "dashed" }}
      >
        <Typography variant="overline">Preisabweichungen</Typography>
      </Divider>
      {services.map(({ service, price_difference, additional_services }) => (
        <Box key={service.id} mb={0.5}>
          <Box sx={styles.serviceWrapper}>
            <Typography sx={styles.serviceName}>{service.name}</Typography>
            <NumberInput
              sx={styles.input}
              value={price_difference / 100 || ""}
              onChange={(value) => {
                props.onChange(
                  props.price_differences.map((service2) =>
                    service2.serviceid === service.id
                      ? {
                          ...service2,
                          price_difference: Math.round((value as number) * 100),
                        }
                      : service2
                  )
                );
              }}
              allowNegative
              decimal
              endAdornment={<Typography>€</Typography>}
            />
          </Box>
          <Box marginLeft={2}>
            {additional_services.map(({ service, price_difference }) => (
              <Box key={service.id} sx={styles.serviceWrapper}>
                <Typography sx={styles.serviceName}>{service.name}</Typography>
                <NumberInput
                  sx={styles.input}
                  value={price_difference / 100 || ""}
                  onChange={(value) => {
                    props.onChange(
                      props.price_differences.map((service2) =>
                        service2.serviceid === service.id
                          ? {
                              ...service2,
                              price_difference: Math.round(
                                (value as number) * 100
                              ),
                            }
                          : service2
                      )
                    );
                  }}
                  allowNegative
                  decimal
                  endAdornment={<Typography>€</Typography>}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
      {services.length === 0 && (
        <Typography variant="caption">Kein Service ausgewählt</Typography>
      )}
      <Divider
        orientation="horizontal"
        sx={{ width: "100%", borderStyle: "dashed" }}
      >
        <Typography variant="overline">Gesamtpreis</Typography>
      </Divider>
      <Box sx={styles.priceDisplay}>
        <Typography>Gesamtpreis:</Typography>
        <Tooltip title="Eingestellter Gesamtpreis dieses Termine">
          <Typography fontWeight="bold">
            {stringifyPrice(totalPrice, settings.getKey("currency_sign"))}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );
}
