import { useInfiniteQuery } from "@tanstack/react-query";
import { queryClient } from "../../../queryClient";
import Server from "../Generic/GenericServer";
import { PartialPick } from "../Generic/GenericTypes";
import { Coupon } from "./CouponTypes";

const CouponServer = {
  async list(cursor?: Date) {
    return Server.get<Coupon[]>("/coupons/:cursor", {
      params: {
        cursor: (cursor ?? new Date(Date.now() + 60_000)).getTime(),
      },
      errorMessage: "Fehler beim Laden der Gutscheine",
    });
  },

  async create(purchase: PartialPick<Coupon, "id">) {
    const id = parseInt(
      await Server.post<string>("/coupons", {
        body: purchase,
        errorMessage: "Fehler beim Erstellen des Gutscheins",
      })
    );

    queryClient.invalidateQueries({
      queryKey: ["coupons"],
      exact: false,
    });

    return id;
  },

  async delete(purchase: Pick<Coupon, "id">) {
    await Server.delete<void>("/coupons/:id", {
      params: {
        id: purchase.id,
      },
      errorMessage: "Fehler beim Löschen des Gutscheins mit id " + purchase.id,
    });

    queryClient.invalidateQueries({
      queryKey: ["coupons"],
      exact: false,
    });
  },

  useInfinite() {
    const res = useInfiniteQuery<Coupon[]>({
      queryKey: ["coupons"],
      queryFn: ({ pageParam }) =>
        CouponServer.list(pageParam as Date | undefined),
      getNextPageParam: (lastPage) => lastPage[lastPage.length - 1]?.created_at,
      initialData: undefined,
      initialPageParam: undefined,
    });
    return {
      ...res,
      data: res.data?.pages.flat(),
    };
  },
};

export default CouponServer;
