import React from "react";
import { Task } from "../../../Server/Services/ServiceTypes";
import TaskVarietyEditor from "./TaskVarietyEditor";
import { ID, Styles } from "../../../../Types";
import { Box, Button, ButtonGroup } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

export interface TaskEditorProps {
  tasks: Task[];
  onChange: (tasks: Task[]) => void;
  serviceid: ID;
}

const styles: Styles = {
  wrapper: {
    width: "100%",
    marginTop: (theme) => theme.spacing(4),
  },
  bottomWrapper: {
    width: "100%",
    marginTop: (theme) => theme.spacing(2),
  },
  varietyButton: {
    position: "relative",
  },
  deleteVarietyButton: {
    marginLeft: (theme) => theme.spacing(1),
    height: "100%",
  },
};

export default function TaskEditor(props: TaskEditorProps) {
  const varieties = React.useMemo(() => {
    const varieties: Task[][] = [];
    props.tasks.forEach((task) => {
      if (!varieties[task.variation_index])
        varieties[task.variation_index] = [];
      varieties[task.variation_index].push(task);
    });
    return varieties;
  }, [props.tasks]);

  // const [selectedVariety] = React.useState(0);
  const [selectedVariety, setSelectedVariety] = React.useState(0);

  return (
    <Box sx={styles.wrapper}>
      {/* select varieties */}
      <Box paddingLeft={2}>
        <Box position="relative">
          <ButtonGroup>
            {varieties.map((_, index) => (
              <Button
                key={index}
                onClick={() => setSelectedVariety(index)}
                variant={selectedVariety === index ? "contained" : "outlined"}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              onClick={() => {
                const newVariety = varieties.length;
                props.onChange?.(
                  props.tasks.concat({
                    id:
                      props.tasks.reduce(
                        (max, task) => Math.max(max, task.id),
                        0
                      ) + 1,
                    name: "",
                    durations: [0, 15],
                    variation_index: newVariety,
                    allowed_employees: [],
                    follow_up_time: 0,
                    preparation_time: 0,
                    serviceid: props.tasks[0].serviceid,
                  })
                );
                setSelectedVariety(newVariety);
              }}
              variant="outlined"
            >
              <Add />
            </Button>
          </ButtonGroup>

          {varieties.length > 1 && (
            <Button
              variant="contained"
              startIcon={<Delete />}
              sx={styles.deleteVarietyButton}
              onClick={() => {
                setSelectedVariety(Math.max(selectedVariety - 1, 0));
                props.onChange?.(
                  props.tasks
                    .filter((task) => task.variation_index !== selectedVariety)
                    .map((task) => {
                      if (task.variation_index > selectedVariety)
                        task.variation_index--;
                      return task;
                    })
                );
              }}
            >
              Variante löschen
            </Button>
          )}
        </Box>
      </Box>
      <Box sx={styles.bottomWrapper}>
        <TaskVarietyEditor
          tasks={varieties[selectedVariety]}
          onChange={(tasks) => {
            const otherTasks = varieties
              .filter((_, index) => index !== selectedVariety)
              .flat();
            props.onChange(otherTasks.concat(tasks));
          }}
          serviceid={props.serviceid}
        />
      </Box>
    </Box>
  );
}
