import React from "react";

import { SimpleDate } from "@idot-digital/calendar-api";

import AppointmentPopupContext from "./AppointmentPopupContext";
import AppointmentPopupManager from "./AppointmentPopupManager";
import { useServer } from "../../../Server/ServerContext";
import config from "../../../../config";
import {
  Appointment,
  AppointmentAttributes,
  AppointmentDurations,
  EMPTY_APPOINTMENT,
  Price,
} from "../../../Server/Appointments/AppointmentTypes";
import { Employee } from "../../../Server/Employees/EmployeeTypes.d";
import { Service } from "../../../Server/Services/ServiceTypes.d";
import { Customer } from "../../../Server/Customers/CustomerTypes.d";
import { ID } from "../../../../Types";
import AppointmentFunctions from "../../../Server/Appointments/AppointmentFunctions";
import EmployeeServer from "../../../Server/Employees/EmployeeServer";
import ServiceServer from "../../../Server/Services/ServiceServer";
import CustomerServer from "../../../Server/Customers/CustomerServer";
import AccountServer from "../../../Server/Accounts/AccountServer";
import { ListAccount } from "../../../Server/Accounts/AccountTypes";
import AppointmentServer from "../../../Server/Appointments/AppointmentServer";
import { deepCopy } from "../../../../Functions/ObjectFunctions";

export interface AppointmentPopupProps {
  appointment?: Appointment;

  open?: boolean;
  edit?: boolean;
  isNew?: boolean;

  setEdit?: (edit: boolean) => void;
  onClose?: () => void;
  onClosed?: () => void;
  getTimes?: () => Promise<{ start: SimpleDate; employeeid: ID }>;

  // callback when saving appointment or saving customer
  onSave?: () => void;
}

export interface AppointmentPopupData extends AppointmentPopupProps {
  mounted: React.MutableRefObject<boolean>;
  update: any;

  appointment: Appointment;
  setAppointmentData: (
    data: Partial<Appointment>,
    callback?: (appointment: Appointment) => void
  ) => void;
  setAppointmentDurations: (
    durations: AppointmentDurations[],
    callback?: (appointment: Appointment) => void
  ) => void;

  default_durations: AppointmentDurations[] | null;
  durations: AppointmentDurations[];
  mainEmployee: Employee | null;
  contrastColor: string;
  services: Service[] | null;
  main_services: Service[];
  additional_services: Service[];
  price: number | null;
  totalPrice: number;
  defaultPrice: Price;
  createdBy: ListAccount | null;
  createdAt: Date | null;
  online: boolean;
  attributes: AppointmentAttributes | null;
  customer: Customer | null;
  appointmentValid: boolean;
  manual_mode: boolean;

  loaded: boolean;
  appointmentError: string | null;
  timeError: boolean;
}

export default function AppointmentPopup(props: AppointmentPopupProps) {
  props = {
    open: false,
    edit: false,
    isNew: false,
    ...props,
  };
  const { account } = useServer();

  // Check mounted state
  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const parseAppointment = React.useCallback(
    () =>
      AppointmentFunctions.copy(
        props.appointment || EMPTY_APPOINTMENT(account)
      ),
    [props.appointment, account]
  );

  const [update, queueUpdate] = React.useReducer((x) => !x, false);
  const [appointment, setAppointment] = React.useState(parseAppointment());

  React.useEffect(() => {
    if (props.open) {
      setAppointment(parseAppointment());
      // Queue update in order to prevent lifecycle errors when opening
      // children could use invalid data from former appointments
      queueUpdate();
    }
    // Only update on new appointment prop and when opening
    // eslint-disable-next-line
  }, [props.appointment, props.open]);

  const internalSetAppointmentData = React.useCallback(
    (
      data: Partial<Appointment>,
      callback?: (appointment: Appointment) => void
    ) => {
      setAppointment((appointment) => {
        const newAppointment = {
          ...appointment,
          ...data,
        };

        callback?.(newAppointment);

        return newAppointment;
      });
    },
    []
  );

  const internalSetAppointmentDurations = React.useCallback(
    (
      durations: AppointmentDurations[],
      callback?: (appointment: Appointment) => void
    ) => {
      setAppointment((appointment) => {
        const newAppointment = {
          ...appointment,
          durations,
        };

        callback?.(newAppointment);

        return newAppointment;
      });
    },
    []
  );

  const durations = React.useMemo(
    () => appointment.durations,
    [appointment.durations]
  );

  const timeError = React.useMemo(
    () => AppointmentFunctions.hasConflictingDurations(durations),
    [durations]
  );

  const { data: mainEmployee = null, isSuccess: mainEmployeeLoaded } =
    EmployeeServer.use(appointment.main_employeeid);

  const contrastColor = React.useMemo(
    () =>
      config.employeeColors.find((color) => color.color === mainEmployee?.color)
        ?.contrastText || "#fff",
    [mainEmployee]
  );

  const servicesRes = ServiceServer.useMultiple(
    appointment.services.map((s) => s.serviceid as ID)
  );

  const services = React.useMemo(
    () =>
      servicesRes
        .map((res) => res.data)
        .filter((service) => service) as Service[],
    [servicesRes]
  );
  const servicesLoaded = servicesRes.every((res) => res.isSuccess);

  const main_services = React.useMemo(
    () => services.filter((service) => !service.is_additional_service),
    [services]
  );
  const additional_services = React.useMemo(
    () => services.filter((service) => service.is_additional_service),
    [services]
  );

  const price = React.useMemo(
    () => appointment.price || null,
    [appointment.price]
  );

  const totalPrice = React.useMemo(
    () =>
      (price || 0) +
      appointment.services.reduce(
        (total, service) => total + service.price_difference,
        0
      ),
    [appointment.services, price]
  );

  const {
    data: defaultPrice = {
      default_price: 0,
      discountid: null,
      discounted_price: 0,
    },
    isSuccess: defaultPriceLoaded,
  } = AppointmentServer.usePrice(
    durations[0].start,
    services.map(({ id }) => id),
    mainEmployee?.id
  );

  // Autofill current price if price did not change
  React.useEffect(() => {
    if (!defaultPriceLoaded) return;
    // preserve discount while new and had discount
    if (props.isNew && appointment.discountid) {
      internalSetAppointmentData({
        price: defaultPrice.discounted_price,
        discountid: defaultPrice.discountid,
      });
      // update price if is new or recalculation scheduled by setting price to null
    } else if (props.isNew || price === null) {
      internalSetAppointmentData({
        price: defaultPrice.default_price,
        discountid: null,
      });
    }
  }, [
    defaultPrice,
    price,
    props.isNew,
    defaultPriceLoaded,
    internalSetAppointmentData,
  ]);

  const { data: createdBy = null, isSuccess: createdByLoaded } =
    AccountServer.use(appointment.created_by);

  const createdAt = React.useMemo(
    () => appointment.created_at || null,
    [appointment.created_at]
  );

  const online = React.useMemo(() => appointment.online, [appointment.online]);

  const attributes = React.useMemo(
    () => appointment.attributes,
    [appointment.attributes]
  );

  const { data: customer = null, isSuccess: customerLoaded } =
    CustomerServer.use(appointment.customerid);

  const manual_mode = React.useMemo(
    () => appointment.manual_mode,
    [appointment.manual_mode]
  );

  // null means there are no default durations -> server could not find any matching combinations
  const { data: default_durations = null, isSuccess: defaultDurationsLoaded } =
    AppointmentServer.useDurations(
      appointment.services
        .map(({ serviceid }) => serviceid)
        .filter((s) => s !== null) as ID[],
      appointment.durations[0].start,
      appointment.main_employeeid,
      props.isNew ? undefined : appointment.id
    );

  React.useEffect(() => {
    if (!manual_mode && default_durations)
      internalSetAppointmentDurations(deepCopy(default_durations));
  }, [default_durations, manual_mode, internalSetAppointmentDurations]);

  const appointmentValid = React.useMemo(
    // if manual mode is not enabled and there are no default durations the appointment is invalid since there are no possible durations for the service
    () =>
      AppointmentFunctions.isValid(appointment) &&
      (manual_mode || default_durations !== null),
    [appointment, default_durations, manual_mode]
  );

  const loaded = React.useMemo(() => {
    if (!servicesLoaded && appointment.services.length) return false;
    if (!mainEmployeeLoaded && appointment.main_employeeid > 0) return false;
    if (!defaultDurationsLoaded) return false;
    if (
      !customerLoaded &&
      appointment.customerid !== null &&
      appointment.customerid > 0
    )
      return false;
    if (!createdByLoaded && appointment.created_by !== null) return false;
    return true;
  }, [
    servicesLoaded,
    customerLoaded,
    mainEmployeeLoaded,
    createdByLoaded,
    defaultDurationsLoaded,
    appointment.created_by,
    appointment.customerid,
    appointment.main_employeeid,
    appointment.services,
  ]);

  // check data when manual mode gets disabled
  React.useEffect(() => {
    if (manual_mode) return;
    // filter out services that the main employee has no time for or is not allowed to to (not allowed for any of the tasks)
    const filtered_services = services
      .filter((service) => {
        if (!service.prices[appointment.main_employeeid]) return false;
        if (
          !service.tasks.some((task) =>
            task.allowed_employees.includes(appointment.main_employeeid)
          )
        )
          return false;
        return true;
      })
      .map((service) => ({
        serviceid: service.id,
        price_difference:
          appointment.services.find(({ serviceid }) => serviceid === service.id)
            ?.price_difference || 0,
      }));
    internalSetAppointmentData({ services: filtered_services });
    // only update when manual_mode changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manual_mode]);

  /*          Exposed setters          */
  // Firing additional events in order to update the appointment

  const setAppointmentData = React.useCallback(
    (
      data: Partial<Appointment>,
      callback?: (appointment: Appointment) => void
    ) => {
      // Fire additional events
      // autofillPrice(data);

      internalSetAppointmentData(data, callback);
    },
    [internalSetAppointmentData]
  );

  const setAppointmentDurations = React.useCallback(
    (
      durations: AppointmentDurations[],
      callback?: (appointment: Appointment) => void
    ) => {
      // Fire additional events

      internalSetAppointmentDurations(durations, callback);
    },
    [internalSetAppointmentDurations]
  );

  /*          Helper functions          */

  const appointmentError = React.useMemo(() => null as null | string, []);

  return (
    <AppointmentPopupContext
      {...{
        ...props,

        mounted,
        update,

        appointment,
        setAppointmentData,
        setAppointmentDurations,

        default_durations,
        durations,
        mainEmployee,
        contrastColor,
        services,
        main_services,
        additional_services,
        price,
        totalPrice,
        defaultPrice,
        createdBy,
        createdAt,
        online,
        attributes,
        customer,
        appointmentValid,
        manual_mode,

        loaded,
        appointmentError,
        timeError,
      }}
    >
      <AppointmentPopupManager />
    </AppointmentPopupContext>
  );
}
