import React from "react";

import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import { Box, IconButton, Typography, Popover } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";

import { SimpleDate } from "@idot-digital/calendar-api";

import { Styles } from "../../../Types";

export interface DayPickerProps {
  onChange: (day: SimpleDate) => void;
  value?: SimpleDate;
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  weekText: {
    cursor: "pointer",
    width: "100%",
    maxWidth: 250,
    textAlign: "center",
    margin: (theme) => theme.spacing(2),
  },
};

export default function DayPicker(props: DayPickerProps) {
  const [day, setDay] = React.useState<SimpleDate | null>(
    props.value ?? SimpleDate.now()
  );

  React.useEffect(() => {
    if (props.value && props.value.exportDayInt() !== day?.exportDayInt())
      setDay(props.value.copy());
  }, [props.value]);

  const [popover, setPopover] = React.useState(false);

  const typographyRef = React.useRef<HTMLElement | null>();

  React.useEffect(
    () => props.onChange(day || SimpleDate.now()),
    //eslint-disable-next-line
    [day]
  );

  return (
    <Box sx={styles.wrapper}>
      <IconButton
        onClick={() =>
          setDay((day) => day?.copy().add(0, 0, -1, 0) || SimpleDate.now())
        }
      >
        <ArrowBackIosRounded />
      </IconButton>
      <Typography
        sx={styles.weekText}
        ref={(ref) => (typographyRef.current = ref)}
        onClick={() => setPopover(!popover)}
      >
        {`${(day || SimpleDate.now()).getDateString(true)}`}
      </Typography>
      <IconButton
        onClick={() =>
          setDay((day) => day?.copy().add(0, 0, 1, 0) || SimpleDate.now())
        }
      >
        <ArrowForwardIosRounded />
      </IconButton>
      <Popover
        open={popover}
        anchorEl={typographyRef.current}
        onClose={() => setPopover(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Box
          sx={{
            ".MuiTypography-root": {
              display: "none",
            },
            ".MuiPickersDay-today": {
              "&:not(.Mui-selected)": {
                background: (theme) => theme.palette.text.disabled,
              },
            },
          }}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={day?.toDate() || new Date()}
            onChange={(day) => setDay(SimpleDate.fromDate(day || new Date()))}
            views={["day"]}
            openTo="day"
            // don't show days outside current month since they don't give callbacks
          />
        </Box>
      </Popover>
    </Box>
  );
}
